<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Courses</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Courses
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.courses.index')" class="btn btn-outline-primary float-md-right">
                        <i class="feather icon-plus icon-left"></i>
                    All Courses 
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Course</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="isEditing ? updateCourse() : createCourse()">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Title</label>
                                        <input type="text" class="form-control" placeholder="Course Title"
                                               required=""
                                               v-model="course.title"
                                               data-validation-required-message="This title field is required">
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label class="form-label">Programme</label>
                                        <select v-model="course.programme_id" id="" class="form-control" required>
                                            <option value="" selected>Select a programme</option>
                                            <option v-for="programme in programmes" :key="programme.id" :value="programme.id">
                                                {{ programme.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div v-if="!isEditing" class="row"> -->
                        <div class="row">
                            <div class="col-12 mb-3" v-if="isEditing">
                                <label>Current Image:</label><br>
                                <img v-if="course.image_url !== null" width="50%" style="height: 250px; border-radius: 20px; background-size: cover;" :src="course.image_url" alt="">
                                <span v-else><b>No Image</b></span>
                            </div>
                            <div class="col-12 mb-3">
                                <label v-if="!isEditing" class="form-label">Course Image</label>
                                <label v-if="isEditing" class="form-label">Edit Course Image (optional)</label>

                                <file-pond
                                    v-if="true"
                                    name="test"
                                    ref="courseImage"
                                    label-idle="Drag & drop course image"
                                    :allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    data-allow-reorder="true"
                                    data-max-file-size="3MB"
                                    :files="courseImage"
                                />


                                
                             </div>
                        </div>


                        <div class="mb-3">
                            <label for="title" class="form-label">Description</label>
                            <vue-editor v-model="course.description"></vue-editor>
                        </div>


                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Create Course'},
    name: 'CourseCreate',
    props: ['isEditing', 'courseData', 'programmes'],
    components: {FilePond, ValidationErrors, VueEditor, Link},
    layout: Layout,
    data() {
        return {
            course: {},
            saving: false,
            courseImage: [],
        }
    },
    methods: {
        createCourse() {
            this.makePostAndPutRequest(route('admin.courses.store'));
        },

        updateCourse() {
            this.makePostAndPutRequest(route('admin.courses.update', this.course.id));
        },

        makePostAndPutRequest(route) {
            try {
                this.saving = true;
                const formData = new FormData();
                Object.keys(this.course).forEach(course => {
                    if (this.course[course]) {
                        formData.append(course, this.course[course]);
                    }
                });

                if (this.$refs.courseImage && this.$refs.courseImage.getFile()) {
                    formData.append('image', this.$refs.courseImage.getFile().file);
                }

                let vm = this;

                this.$inertia.post(route, formData, {
                    preserveScroll: true,
                    onError: function (e) {
                        console.error(e);
                        vm.saving = false
                    },
                    onSuccess: function () {
                        vm.saving = false
                    },
                });

            } catch (e) {
                console.log(e);
                this.saving = false;
            }
        },
    },
    created() {

    },
    mounted() {
        if (this.isEditing) {
            this.course = this.courseData;
            // this.courseImage = this.news.image;
        }
    }
}
</script>

<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Application Submissions</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><Link :href="route('admin.applications.index')">Applications</Link>
                            </li>
                            <li class="breadcrumb-item active">Application Submissions
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Students who have submitted applications:</h4>
                </div>

                <div class="card-body">

                    <vue-good-table :columns="columns" :rows="submissionsData" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'dateAdded', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                        }">


                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <!-- <span v-if="props.column.field === 'sn'">
                                {{ serialNo(props) }}
                            </span> -->
                            <span v-if="props.column.field === 'action'">
                                <span class="dropdown">
                                    <a id="btnSearchDrop2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        class="dropdown-toggle dropdown-menu-right"><i class="fa fa-ellipsis-v"></i></a>
                                    <span aria-labelledby="btnSearchDrop2" class="dropdown-menu mt-1 dropdown-menu-right"
                                        x-placement="bottom-end"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 19px, 0px);"
                                        x-out-of-boundaries="">
                                        <Link :href="route('admin.applications.getStudentQuestionAndAnswers',props.row.id)" class="dropdown-item edit"><i class="feather icon-edit-2"></i>
                                            View Submission
                                        </Link>
                                        <a href="" class="dropdown-item edit"><i class="feather icon-edit-2"></i>
                                            Edit
                                        </a>
                                    </span>
                                </span>
                            </span>

                        </template>
                    </vue-good-table>

                </div>
            </section>
        </div>

        <div class="modal fade view-result-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="viewResult">
                        <div class="modal-header"><h4 id="myModalLabel1" class="modal-title">Results for the STUDENT</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">

                            <div class="form-group"><label>Student</label>
                                <input type="text" required="required"
                                       class="form-control">
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Approve <i class="fa fa-spinner fa-spin" v-if="saving"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import moment from 'moment';
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'Submitted Applications' },
    props: ['submissions','applicationId','questionAndAnswers'],
    components: {
        Link
    },
    name: "SubmittedApplications",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        submissionsData: [],
        studentQuestionAndAnswers: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // { label: 'S/N', field: 'sn' },
            { label: 'First Name', field: 'first_name' },
            { label: 'Last Name', field: 'last_name' },
            { label: 'Email', field: 'email' },
            { label: 'Approval Status', field: 'applicationStatus' },
            // {label: 'Status', field: 'status'},
            { label: 'Action', field: 'action', sortable: false },
        ],
        tableDataLoading: false,
        saving: false
    }),
    created() {
        console.log('this.submissions.data', this.submissions)
        this.submissionsData = this.submissions
        this.totalRecords = this.submissions.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.applications.paginate';

                let { data: { data: applicationForms } } = await axios.post(route(routeName),
                    { ...this.serverParams });

                this.totalRecords = applicationForms.total;
                this.paginationOptions.perPage = applicationForms.per_page;
                this.allApplicationForms = applicationForms.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteApplicationForm(applicationFormId) {
            this.$inertia.delete(route('admin.applications.destroy', applicationFormId), {
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                (this.serverParams.page - 1)) +
                props.index + 1
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            } else {
                return '-';
            }
        },
    }
}
</script>

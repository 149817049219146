<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Assignments</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">
                                Add Assignment
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.assignments.index')"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-eye icon-left"></i>
                    All Assignments 
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Assignment</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="isEditing ? updateAssignment() : addAssignment()">

                        <h2>{{ isEditing ? 'Edit' : 'Add' }} Assignment</h2>
                        <div class="form-group">
                            <label for="assignmentName">Assignment Title:</label>
                            <input id="assignmentName" type="text" class="form-control" v-model="assignmentName" required>
                        </div>

                        <div class="form-group">
                            <label for="assignmentDescription">Description:</label>
                            <textarea cols="5" rows="10" id="assignmentDescription" class="form-control" v-model="assignmentDescription"></textarea>
                        </div>

                        <div class="form-group">
                            <label for="assignmentType">Is it a Group Assignment?</label>                            
                            <div class="controls">
                                <select required @change="isGroupAssignmentMethod($event)" v-model="assignmentType" id="customSelect" class="custom-select block">
                                    <option value="" disabled selected="">
                                        Is it a group assignment?
                                    </option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                
                            </div>
                            
                        </div>

                        <div  v-if="isGroupAssignment" class="form-group">
                            <label for="assignmentGroup">
                                Group Assignment:
                            </label>
                            <select v-model="assignmentGroup"  id="customSelect" class="custom-select block" required>
                                <option value="" selected>Select Group</option>
                                <option v-for="(data, key) in groupsData" :value="data.id">{{data.name}}</option>
                            </select>
                        </div>


                        <div v-if="!isGroupAssignment" class="form-group">
                            <label for="course">Course:</label>
                            <div class="controls">
                                <select  v-model="courseId" @change="fetchCourseModules($event)"  id="customSelect" class="custom-select block">
                                    <option value="" disabled selected>Select Course</option>
                                    <option v-for="(data, key) in courses" :value="data.id">{{data.title}}</option>
                                </select>
                            </div>
                        </div>

                        <div v-if="!isGroupAssignment" class="form-group">
                            <label for="assignmentModule">Module:</label>
                            <div class="controls">
                                <select v-model="assignmentModule"  id="customSelect" class="custom-select block">
                                    <option value="" disabled selected>Select assignment Module</option>
                                    <option v-for="(data, key) in modulesData" :value="data.id">{{data.title}}</option>
                                </select>
                            </div>
                        </div>

                       
                        <div class="form-group">
                            <label for="assignmentCohort">Cohort:</label>
                            
                            <div class="controls">   
                                <select v-model="assignmentCohort"  id="customSelect" class="custom-select block">
                                    <option value="" selected>Select assignment Cohort</option>
                                    <option v-for="(data, key) in cohortsData" :value="data.id">{{data.name}}</option>
                                </select>
                            </div>
                            
                        </div>
                        <div class="form-group">
                            <label for="assignmentDueDate">Due Date:</label>
                            <input id="assignmentDueDate" v-model="assignmentDueDate" type="datetime-local" class="form-control"  required>
                        </div>

                        <hr>

                        <button type="submit" class="btn btn-primary">Add Assignment</button>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Create/Edit assignment'},
    name: 'AssignmentCreate',
    props: ['isEditing', 'modules','groups','cohorts','courses', 'moduleid', 'assignmentData'],
    components: {FilePond, ValidationErrors, VueEditor, Link},
    layout: Layout,
    data() {
        return {
            assignment: {},
            saving: false,
            isGroupAssignment: false,
            modulesData: {},
            allCourses: [],
            courseId:null,
            groupsData: {},
            cohortsData: {},
            assignmentType: "",
            assignmentDueDate: "",
            assignmentModule: "",
            assignmentGroup: "",
            assignmentCohort: "",
            assignmentName: "",
            assignmentDescription: "",
            assignmentId: "",
        }
    },
    methods: {
        isGroupAssignmentMethod(e) {
            this.isGroupAssignment =  e.target.value == "yes" ? true : false;
        },
        addAssignment() {
            let vm = this;
            vm.saving = true;

            const assignmentData = {
                module: this.assignmentModule,
                course: this.courseId,
                group: this.assignmentGroup,
                cohort: this.assignmentCohort,
                due_date: this.assignmentDueDate,
                name: this.assignmentName,
                description: this.assignmentDescription
            }
            this.$inertia.post(route('admin.assignments.store',assignmentData),
            {
                preserveScroll: true,
                onSuccess: function () {
                    vm.saving = false;
                },
                onError: function () {
                    vm.saving = false;
                },
                }
            );
            // console.log(assignmentData)
        },
        
        updateAssignment() {
            let vm = this;
            vm.saving = true;

            const assignmentData = {
                id: this.assignmentId,
                due_date: this.assignmentDueDate,
                name: this.assignmentName,
                description: this.assignmentDescription,
                module: this.assignmentModule,
                course: this.courseId,
                group: this.assignmentGroup,
                cohort: this.assignmentCohort,

            };
            this.$inertia.post(route("admin.assignments.update", this.assignmentId),
                assignmentData,
                    {
                        preserveScroll: true,
                        onSuccess: function () {
                            vm.saving = false;
                        },
                        onError: function () {
                            vm.saving = false;
                        },
                    }
            );
            // this.makePostAndPutRequest(route('admin.assignments.update', this.course.id));
        },

        async fetchCourseModules(){
            let {data:{data:data}} = await axios.get(route('admin.courses.modules.index', this.courseId))

            this.modulesData = data.modules
        }

       
    },
    created() {
        this.groupsData = this.groups;
        this.cohortsData = this.cohorts;
        this.allCourses = this.courses;

    },
    mounted() {
        if (this.isEditing) {
            console.log("Assignment Data:", this.assignmentData);
            this.assignmentId = this.assignmentData.id;
            this.assignmentName = this.assignmentData.name;
            this.assignmentDescription = this.assignmentData.description;
            this.assignmentDueDate = this.assignmentData.due_date;

            this.assignmentGroup = this.assignmentData.group_id !== null;

            // module: this.assignmentModule,
            // course: this.courseId,
            // group: this.assignmentGroup,
            // cohort: this.assignmentCohort,
            // due_date: this.assignmentDueDate,
        }
    },
}
</script>

<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">APPLICATION QUESTIONS</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><Link :href="route('admin.applications.index')">Applications</Link>
                            </li>
                            <li class="breadcrumb-item active"><b>Application Questions</b>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-header table-responsive">

        <h4 class="card-title mb-1">Application Questions for {{ applicationName }}</h4>
            <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
            <div class="heading-elements">
                <ul class="list-inline mb-0">
                    <li><a data-action="expand"><i class="feather icon-maximize"></i></a></li>
                </ul>
            </div>

            <table class="table table-bordered table-striped" compactMode :search-options="{enabled: true,skipDiacritics: true}"
                :pagination-options="{ enabled: true, mode: 'pages', position: 'bottom', perPage: 10 }">
                <thead >
                    <tr class="p-2">
                        <th>SN</th>
                        <th>Question</th>
                        <!-- <th>Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) of questionResult" :key="row.id">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ row.question_text }}</td>
                    </tr>

                </tbody>
            </table>

            </div>


    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import moment from 'moment';
import ValidationErrors from "@/Shared/ValidationErrors";
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'Application Questions' },
    props: ['applicationId', 'applicationQuestions'],
    components: {
        Link,ValidationErrors
    },
    name: "ApplicationFormQuestions",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        questionResult: [],
        applicationName: null,
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            { label: 'S/N', field: 'sn' },
            // { label: 'First Name', field: 'first_name' },
            // { label: 'Last Name', field: 'last_name' },
            // { label: 'Email', field: 'email' },
            // {label: 'Status', field: 'status'},
        ],
        tableDataLoading: false,
        saving: false
    }),
    created() {
        this.questionResult = this.applicationQuestions
        this.applicationName = this.applicationQuestions[0].application_form
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.applications.paginate';

                let { data: { data: applicationQuestions } } = await axios.post(route(routeName),
                    { ...this.serverParams });

                this.totalRecords = applicationQuestions.total;
                this.paginationOptions.perPage = applicationQuestions.per_page;
                this.questionResult = applicationQuestions.data
            } catch (e) {
                console.log(e);
            }
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                (this.serverParams.page - 1)) +
                props.index + 1
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            } else {
                return '-';
            }
        },
    }
}
</script>

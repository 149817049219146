<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">
                    {{ isEditing ? "Edit" : "Add" }} Lesson
                </h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">
                                {{ isEditing ? "Edit" : "Add" }} Lesson
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <!-- <Link :href="route('admin.lessons.index')" -->
                <Link :href="route('admin.lessons.index', moduleid)" type="button"
                    class="btn btn-outline-primary float-md-right"><i class="feather icon-eye icon-left"></i>
                All Lessons
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        {{ isEditing ? "Edit" : "Add" }} Lesson
                    </h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="
                        isEditing ? updatelesson() : addlesson()
                        ">
                        <h2>{{ isEditing ? "Edit" : "Add" }} Lesson</h2>
                        <div class="form-group">
                            <label for="lessonName">Title:</label>
                            <input id="lessonName" type="text" class="form-control" v-model="lessonName" required />
                        </div>

                        <!-- <div class="form-group">
                            <label for="lessonDescription">Description:</label>
                             <vue-editor v-model="lessonDescription"></vue-editor>
                        </div> -->

                        <div class="form-group">
                            <label for="lessonContent">Lesson Content:</label>
                            <vue-editor v-model="lessonContent"></vue-editor>
                        </div>

                        <div class="form-group">
                            <label for="lessonPosition">Position:</label>
                            <input id="lessonPosition" :disabled="isEditing" type="number" class="form-control"
                                v-model="lessonPosition" required />
                        </div>

                        <!-- <div class="form-group">
                            <label for="lessonModule">Module:</label>

                                    <div class="controls">
                                        <select v-model="lessonModule"  id="customSelect" class="custom-select block">
                                            <option value="" selected>Select lesson Module</option>
                                            <option v-for="(data, key) in modulesData" :value="data.id">{{data.title}}</option>
                                        </select>
                                    </div>

                        </div> -->

                        <div class="form-group">
                            <label for="lessonTutor">Tutor:</label>

                            <div class="controls">
                                <select required v-model="lessonTutor" id="customSelect" class="custom-select block">
                                    <option value="" disabled selected>
                                        Select Tutor
                                    </option>
                                    <!-- <option v-if="isEditing" :value="lessonData.tutor_id">{{lessonData.tutor}}</option> -->
                                    <option v-for="(data, key) in tutorsData" :value="data.id">
                                        {{ data.first_name }} -
                                        {{ data.last_name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="lessonType">Is it a Live class?</label>

                            <div class="controls">
                                <select required @change="isLiveClassMethod($event)" v-model="lessonType" id="customSelect"
                                    class="custom-select block">
                                    <option value="" disabled selected="">
                                        Is it a live class?
                                    </option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lessonUrl">
                                {{ isLiveClass == true ? "Live class url" : "Video url" }}
                            </label>
                            <input v-if="isLiveClass" id="lessonUrl" v-model="lessonUrl" disabled type="text"
                                placeholder="Live class url" class="form-control" />

                            <input v-else type="file" accept="video/*" class="form-control" ref="video" @change="setVideoFile" />
                            <!-- <input v-else type="file" accept="video/*" :required="!isEditing && (lessonContent == null || lessonContent == '')"
                            class="form-control" ref="video" @change="setVideoFile" /> -->

                        </div>

                        <div v-if="isLiveClass" class="form-group">
                            <label for="liveClassDate">Live Class Date-WAT</label>
                            <input id="liveClassDate" v-model="liveClassDate" :type="`${isEditing ? 'datetime-local' : 'datetime-local'}`"
                                placeholder="Live class url" class="form-control" required />
                        </div>

                        <hr />

                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                    :disabled="saving">
                                    {{
                                        isEditing
                                        ? "Update lesson"
                                        : "Add lesson"
                                    }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import { VueEditor } from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from "@inertiajs/inertia-vue";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

export default {
    metaInfo: { title: "Create/Edit lesson" },
    name: "LessonCreate",
    props: ["isEditing", "modules", "tutors", "moduleid", "lessonData"],
    components: { FilePond, ValidationErrors, VueEditor, Link },
    layout: Layout,
    data() {
        return {
            lesson: {},
            saving: false,
            isLiveClass: false,
            modulesData: {},
            tutorsData: {},
            lessonType: "",
            liveClassDate: "",
            lessonUrl: "https://staging-admin.africaagility.org/", //default
            lessonTutor: "",
            lessonContent: "",
            lessonModule: "",
            lessonPosition: "",
            lessonName: "",
            lessonVideo: null,
            lessonDescription: "",
            lessonId: "",
        };
    },
    methods: {
        isLiveClassMethod(e) {
            this.isLiveClass = e.target.value == "yes" ? true : false;
        },
        addlesson() {
            let vm = this;
            vm.saving = true;

            const lessonData = {
                module_id: this.moduleid,
                tutor_id: this.lessonTutor,
                is_live_class: this.lessonType,
                live_class_date: this.liveClassDate,
                url: this.lessonUrl,
                text_content: this.lessonContent,
                position: this.lessonPosition,
                title: this.lessonName,
                description: this.lessonDescription,
                video: this.lessonVideo,
            };
            // console.log(lessonData);
            this.$inertia.post(
                route("admin.lessons.store", this.moduleid),
                lessonData,
                {
                    preserveScroll: true,
                    onSuccess: function () {
                        vm.saving = false;
                    },
                    onError: function () {
                        vm.saving = false;
                    },
                }
            );
        },
        addOption(index) {
            this.questions[index].options.push(
                `Option ${this.questions[index].options.length + 1}`
            );
        },

        setVideoFile() {
            this.lessonVideo = this.$refs.video.files[0];
        },

        updatelesson() {
            let vm = this;
            vm.saving = true;

            const lessonData = {
                id: this.lessonId,
                module_id: this.moduleid,
                tutor_id: this.lessonTutor,
                is_live_class: this.lessonType,
                live_class_date: this.liveClassDate,
                url: this.lessonUrl,
                text_content: this.lessonContent,
                position: this.lessonPosition,
                title: this.lessonName,
                description: this.lessonDescription,
                video: this.lessonVideo,
            };
            // console.log(lessonData);
            this.$inertia.post(
                route("admin.lessons.lesson.update", [
                    this.moduleid,
                    this.lessonId,
                ]),
                lessonData,
                {
                    preserveScroll: true,
                    onSuccess: function () {
                        vm.saving = false;
                    },
                    onError: function () {
                        vm.saving = false;
                    },
                }
            );
        },
    },
    created() {
        // console.log('lessonData', this.lessonData)
        this.modulesData = this.modules;
        this.tutorsData = this.tutors;
    },
    mounted() {
        if (this.isEditing) {
            this.lessonId = this.lessonData.id;
            this.lessonTutor = this.lessonData.tutor_id;
            this.lessonContent = this.lessonData.text_content;
            this.lessonModule = this.lessonData.module_id;
            this.lessonPosition = this.lessonData.position;
            this.isLiveClass =
                this.lessonData.is_live_class == 1 ? "yes" : "no";
            this.lessonName = this.lessonData.title;
            this.liveClassDate = this.lessonData.live_class_date;
            this.lessonUrl =
                this.lessonData.is_live_class == 1
                    ? this.lessonData.live_class_url
                    : this.lessonData.video_url;
        }
    },
};
</script>

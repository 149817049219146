<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Cohorts</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Cohorts
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Cohorts</h4>
                </div>

                <div class="card-body">

                    <vue-good-table
                        class="dataTable"
                        mode="remote"
                        :isLoading.sync="tableDataLoading"
                        :totalRows="totalRecords"
                        :pagination-options="paginationOptions"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        styleClass="vgt-table"
                        :columns="columns"
                        :rows="allCohorts"
                    >

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span>
                            <span v-else-if="props.column.label === 'Registration Date'">
                                 Start Date: <b>{{ props.row.reg_start_date }}</b><br>
                                End Date: <b>{{ props.row.reg_end_date }} </b><br>
                            </span>
                            <span v-else-if="props.column.label === 'Status'">
                                  <span class="badge"
                                        :class="{ 'badge-success': props.row.is_active, 'badge-danger': !props.row.is_active}">{{
                                          props.row.is_active ? 'Active' : 'Deactivated'
                                      }}</span>
                                </span>
                            <span v-else-if="props.column.field === 'action'">

                                <span style="display: flex; justify-items: center; align-items: center;" class="dropdown">
                                    <a  :href="route('admin.cohorts.edit',props.row.id)"><i class="feather icon-edit" title="View Cohort"></i></a>
                                    <a  href=""  @click.prevent="showAlert( props.row.id)"><i  title="Delete Cohort" class="feather icon-trash"></i></a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Cohorts'},
    props: ['cohorts'],
    name: "CohortIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allCohorts: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'S/N', field: 'sn'},
            {label: 'Cohort Name', field: 'name'},
            {label: 'Programme', field: 'programme_name'},
            {label: 'Total Capacity', field: 'capacity',  sortable: false},
            {label: 'Student Per Group', field: 'no_per_group'},
            {label: 'Added By', field: 'author'},
            {label: 'Registration Date', field: 'dateAdded'},
            {label: 'Date Added', field: 'dateAdded'},
            {label: 'Status', field: 'status'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    created() {
        console.log('this.cohorts.data', this.cohorts.data)
        this.allCohorts = this.cohorts
        this.totalRecords = this.cohorts.length
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.cohorts.paginate';

                let {data:{data: cohorts}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = cohorts.total;
                this.paginationOptions.perPage = cohorts.per_page;
                this.allCohorts = cohorts.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteCohort(courseId){
            this.$inertia.delete(route('admin.cohorts.destroy', courseId),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },


        showAlert(courseId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Cohort?",
                text: "All histories associated with this cohort will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteCohort(courseId)
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>

<template>
    <div>
      
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Comments</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Comments

                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <button @click.prevent="addCommentModal" data-toggle="modal" data-target="#add-lesson-modal"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add Comment
                </button>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Comments</h4>
                </div>

                <div class="card-body">
                    <vue-good-table :columns="columns" :rows="allComments" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'dateJoined', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                    }">


                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'action'">
                                <span class="dropdown">
                                   <a class="dropdown-item text-danger delete"  href=""  @click.prevent="showAlert( props.row.id)"><i  title="Delete Comment" class="feather icon-trash"></i></a>
                                </span>
                            </span>
                        </template>
                        
                    </vue-good-table>
                </div>
            </section>

            <div class="modal fade add-lesson-modal" id="add-lesson-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="addComment">
                        <div class="modal-header"><h4 id="myModalLabel1" class="modal-title">Add Comment</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <validation-errors></validation-errors>
                            <div class="form-group">
                                <label>Lesson</label>
                                <select class="form-control" v-model="comment.lesson" required>
                                    <option disabled selected>Select lesson</option>
                                    <option v-for="lesson in allLessons" :value="lesson.id">{{lesson.title}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="comment">Comment:</label>
                                <textarea cols="5" rows="10" id="comment" class="form-control" v-model="comment.comment"></textarea>
                            </div>


                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Save <i class="fa fa-spinner fa-spin" v-if="saving"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            </div>

        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';
import ValidationErrors from "../../Shared/ValidationErrors.vue";


export default {
    metaInfo: {title: 'Comments'},
    props: ['comments','lessons'],
    components: {
        Link,
        ValidationErrors
    },
    name: "CommentIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allComments: [],
        comment:{
            comment: null,
            lesson: null,
        },
        saving: false,
        allLessons: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // {label: 'S/N', field: 'sn'},
            {label: 'Title', field: 'comments'},
            {label: 'User', field: 'fullname'},
            {label: 'Admin', field: 'adminFullname'},
            {label: 'Lesson', field: 'lesson.title'},
            {label: 'Date Joined', field: 'dateJoined'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    created() {
        this.allLessons = this.lessons
        this.allComments = this.comments
        this.totalRecords = this.comments.length
    },
    methods: {
        addCommentModal() {
            $('.add-lesson-modal').modal('show');
        },

        addComment() {
            let vm = this;
            vm.saving = true;
            const commentData = {
                comments: vm.comment.comment,
                lesson_id: vm.comment.lesson
            };
            
            this.$inertia.post(route('admin.comments.store'), commentData, {
                preserveScroll: true,
                onError: function (e) {
                    console.error(e);
                    vm.saving = false
                },
                onSuccess: function (e) {
                    console.log(e);
                    vm.saving = false
                    $('.add-lesson-modal').modal('hide');        
                    location.reload(1)
                },
            });
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.tutors.paginate';

                let {data: {data: tutors}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = tutors.total;
                this.paginationOptions.perPage = tutors.per_page;
                this.allTutors = tutors.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteComment(id){
            this.$inertia.delete(route('admin.comments.destroy',id),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });

        },

        showAlert(commentId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this comment?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteComment(commentId)
                }
            });
        },


        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Girls in Tech Students</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Girls in Tech Students
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Girls in Tech Students </h4>
                    <div><strong>Application status can be any of the following:</strong></div>
                    <div>
                        Successful: Student has passed the assessment.
                        <br /> Unsuccessful: Student failed the assessment
                        <br /> Verified: The student's application has been approved
                        <br /> Unverified: The student's application has not been approved or denied
                        <br /> Registered: Student has created an account on the platform only
                    </div>
                </div>

                <div class="card-body">
                    <div v-if="showFilterArea">
                        <h4>Filters</h4>
                        <div class="row mb-3">
                            <div class="col-md-2 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="courses">Course</label>
                                    <select v-model="filter.course" class="form-control" id="course">
                                        <option value="" selected>Select a course</option>
                                        <option v-for="course in courses" :value="course.id">
                                            {{ course.title }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="groups">Group</label>
                                    <select v-model="filter.group" class="form-control" id="group">
                                        <option value="" selected>Select a group</option>
                                        <option v-for="group in groups" :value="group.id">
                                            {{ group.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="prog">Application Status</label>
                                    <select v-model="filter.progress" class="form-control" id="prog">
                                        <option value="" selected>Select a progress status</option>
                                        <option class="text-title" v-for="progress in progressStatuses" :value="progress">
                                            {{ progress }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="statuss">Activation Status</label>
                                    <select v-model="filter.status" class="form-control" id="status">
                                        <option value="" selected>Select a status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inctive</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-6">
                                <button class="btn btn-dark mt-2" v-on:click="getFilteredStudents()">Filter</button>
                                <button class="btn btn-dark mt-2" v-on:click="resetStudents()">Reset Table</button>
                            </div>
                        </div>
                    </div>
                    <vue-good-table :columns="columns" :rows="allStudents" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                            initialSortBy: { field: 'dataJoined', type: 'desc' }
                        }" :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'bottom',
                            perPage: 10
                        }"
                    >

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            
                            <span v-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>
                            <span v-else-if="props.column.label === 'Name'">
                                {{
                                    props.row.first_name + ' ' + props.row.last_name
                                }}
                            </span>
                            <span v-else-if="props.column.label === 'Activation Status'">
                                <span class="badge"
                                    :class="{ 'badge-success': props.row.activation_status == 1, 'badge-danger': props.row.activation_status == 0 }">
                                    {{ props.row.activation_status == 1 ? "Active" : "Deactivated" }}
                                </span>
                            </span>
                            <span v-else-if="props.column.label === 'Status'">
                                <span class="badge"
                                    :class="{ 'badge-success': props.row.status == 'Successful', 'badge-info': props.row.status != 'Successful', 'badge-danger': props.row.status == 'Unsuccessful' }">{{
                                        props.row.progress
                                    }}</span>
                            </span>
                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">
                                    <a href="" :href="route('admin.students.edit', props.row.id)">
                                        <i title="Edit Student" class="feather icon-edit">
                                        </i>
                                    </a>
                                    <a href="" @click.prevent="showAlert(props.row.id, props.row.status)">
                                        <i :title="props.row.status == 1 ? 'Disable' : 'Activate'" class="fa fa-user">
                                        </i>
                                    </a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';


export default {
    metaInfo: { title: 'GirlsInTech' },
    props: ['students', 'progressStatuses', 'courses', 'programmes', 'cohorts', 'groups'],
    name: "GirlsInTechIndex",
    layout: Layout,

    data: () => ({
        filter: {
            // course : null,
        },
        showFilterArea: true,
        isLoadingFilter: false,
        allStudents: [],
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            { label: 'Name', field: 'name' },
            { label: 'Email', field: 'email' },
            { label: 'Phone', field: 'phone' },
            { label: 'Course', field: 'course.title' },
            { label: 'Group', field: 'group' },
            { label: 'Programme', field: 'course.programme.name' },
            { label: 'Date Joined', field: 'dateJoined' },
            { label: 'Activation Status', field: 'activation_status' },
            { label: 'Status', field: 'progress' },
            { label: 'Action', field: 'action', sortable: false },
        ],
        tableDataLoading: false,
    }),
    created() {
        this.allStudents = this.students.data
    },
    methods: {
        showAlert(id, status) {
            let vInstance = this;
            let action = status ? 'deactivate' : 'activate'
            this.$swal.fire({
                title: `Are you sure you want to ${action} student's account?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${action} it!`,
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.updateStatus(id)
                }
            });
        },
        updateStatus(id) {
            this.$inertia.get(route('admin.students.updateStudentStatus', id), {
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.students.paginate';

                let { data: { data: students } } = await axios.get(route(routeName),
                    { ...this.serverParams });

                this.totalRecords = students.total;
                this.paginationOptions.perPage = students.per_page;
                this.allStudents = students.data
            } catch (e) {
                console.log(e);
            }
        },

        async getFilteredStudents() {
            try {
                let routeName = 'admin.students.filter';

                axios.get(route(routeName), { params: this.filter })
                    .then(response => {
                        let { data: { data: students } } = response

                        this.allStudents = students.data
                        console.log('students.data', students)
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } catch (e) {
                console.log(e);
            }
        },



        async resetStudents() {
            this.allStudents = this.students.data
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>

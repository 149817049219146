<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Quizzes</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">{{ isEditing ? 'Edit' : 'Add' }} Quiz - Assessments
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.quizzes.index')"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-eye icon-left"></i>
                         All Quizzes
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Quiz</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="isEditing ? updateQuiz() : addQuiz()">

                        <h2>{{ isEditing ? 'Edit' : 'Add' }} Quiz  - Assessments</h2>
                        <div class="form-group">
                            <label for="quizTitle">Quiz Title:</label>
                            <input id="quizTitle" type="text" class="form-control" v-model="quizTitle" required>
                        </div>

                        <div class="form-group">
                            <label for="course">Course:</label>
                            <div class="controls">
                                <select required v-model="courseId" @change="fetchCourseModules($event)"  id="customSelect" class="custom-select block">
                                    <option value="" disabled selected>Select Course</option>
                                    <option v-for="(data, key) in courses" :value="data.id">{{data.title}}</option>
                                </select>
                            </div>
                        </div>


                        <div class="form-group">
                            <label for="quizModule">Quiz Module:</label>
                            <div class="controls">
                                <select v-model="quizModule"  id="customSelect" class="custom-select block">
                                    <option value="" disabled selected>Select Quiz Module</option>
                                    <option v-for="(data, key) in modulesData" :value="data.id">{{data.title}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="quizTimeAllowed">Time Allowed  (in minutes) - Optional</label>
                            <input id="quizTimeAllowed" v-model="quizTimeAllowed" type="number" min="1" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="quizDescription">Quiz Description:</label>
                            <textarea id="quizDescription" class="form-control" v-model="quizDescription"></textarea>
                        </div>

                        <hr>

                        <div v-for="(question, index) in questions" :key="index" class="mb-4">
                            <h3>Question {{ index + 1 }}</h3>
                            <div class="form-group">
                                <label for="questionText">Question Text:</label>
                                <textarea required id="questionText" class="form-control" v-model="question.text"></textarea>
                            </div>
                            <div v-if="question.type === 'multiple-choice'">
                                <div class="form-group" v-for="(option, optionIndex) in question.options" :key="optionIndex">
                                    <div class="form-inline">
                                        <input type="checkbox" class="form-check-input mr-2" v-model="question.correctAnswers" :value="optionIndex">
                                        <input type="text" class="form-control mr-2" v-model="question.options[optionIndex]" required>
                                        <button type="button" class="btn btn-danger btn-sm" @click="removeOption(index, optionIndex)">Remove Option</button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" @click="addOption(index)">Add Option</button>
                                </div>
                            </div>
                            <!-- <div v-else>
                                <div class="form-group">
                                    <label for="answerText">Answer:</label>
                                    <input id="answerText" required type="text" class="form-control" v-model="question.answer">
                                </div>
                            </div> -->
                            <!-- <div class="form-group">
                                <label for="questionPoints">Points:</label>
                                <input id="questionPoints" type="number" required class="form-control" v-model.number="question.points" min="1" value="0">
                            </div> -->
                            <button type="button" class="btn btn-danger" @click="removeQuestion(index)">Remove Question</button>
                        </div>

                        <hr>
                        <br> <br>

                        <div class="form-group">
                            <button type="button" class="btn btn-primary" @click="addQuestion('multiple-choice')">Add
                                Multiple-Choice Question
                            </button>
                            <!-- <button type="button" class="btn btn-primary" @click="addQuestion('text-based')">Add
                                Text-Based Question
                            </button> -->
                        </div>

                        <hr>

                        <button type="submit" class="btn btn-primary">Add Quiz</button>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';
import axios from 'axios';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Create Quiz'},
    name: 'QuizCreate',
    props: ['isEditing','courses'],
    components: {FilePond, ValidationErrors, VueEditor, Link},
    layout: Layout,
    data() {
        return {
            quiz: {},
            saving: false,
            modulesData: {},
            allCourses: [],
            courseId:null,
            quizTimeAllowed: '',
            quizModule: '',
            quizTitle: '',
            quizDescription: '',
            questions: [
                {type: 'multiple-choice', text: '', options: ['', '', ''], correctAnswers: [], points: 0},
                // {type: 'text-based', text: '', answer: '', points: 0}
            ]
        }
    },
    methods: {
        addQuestion(type) {
            const question = {
                type,
                text: '',
                options: ['Option 1', 'Option 2'],
                correctAnswers: [],
                answer: '',
                points: 0
            };
            this.questions.push(question);
        },
        removeQuestion(index) {
            const questionPoints = this.questions[index].points;
            this.questions.splice(index, 1);
            this.totalPoints -= questionPoints;
        },
        removeOption(questionIndex, optionIndex) {
            const question = this.questions[questionIndex];
            const optionPoints = question.points / question.options.length;
            question.options.splice(optionIndex, 1);
            question.correctAnswers = question.correctAnswers.filter((index) => index !== optionIndex);
            question.points -= optionPoints;
        },
        addQuiz() {
            const quizData = {
                module: this.quizModule,
                course: this.courseId,
                timeAllowed: this.quizTimeAllowed,
                name: this.quizTitle,
                description: this.quizDescription,
                questions: this.questions
            }

            this.$inertia.post(route('admin.quizzes.store',quizData));

            // console.log(quizData)


             // code to add quiz to database goes here
            //after successful addition, clear the inputs
            // this.quizTitle = ''
            // this.quizDescription = ''
            // this.questions = [
            //     {text: '', options: ['', '', ''], correctAnswers: [], points: 1}
            // ]
        },
        addOption(index) {
            this.questions[index].options.push(`Option ${this.questions[index].options.length + 1}`);
        },


        updateQuiz() {
            // this.makePostAndPutRequest(route('admin.quizzes.update', this.course.id));
        },

        async fetchCourseModules(){
            let {data:{data:data}} = await axios.get(route('admin.courses.modules.index', this.courseId))

            this.modulesData = data.modules
        }


    },
    created() {
        this.allCourses = this.courses;
    },
    mounted() {
        // if (this.isEditing) {
            // this.quiz = this.quizzes;
        //     // this.courseImage = this.news.image;
        // }
    }
}
</script>

<template>
    <div>
        
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">All Broadcasts</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">All Broadcasts

                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.broadcasts.create')"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                     Send Broadcast
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Broadcasts</h4>
                </div>
                <div class="card-body">
                <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'created_at', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <template slot="table-row" slot-scope="props">         
                        <span v-if="props.column.field === 'action'">
                                <span class="dropdown">
                                    <a  :href="route('admin.broadcasts.show',props.row.id)"><i class="feather icon-eye" title="View Broadcast"></i></a>
                                    <a class="text-danger"  href=""  @click.prevent="showDeleteAlert( props.row.id)"><i  title="Delete Broadcast" class="feather icon-trash"></i></a>
                                </span>

                        </span>


                    </template>
                    
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';



export default {
    metaInfo: {title: 'Broadcasts'},
    props: ['broadcasts'],
    layout: Layout,
    components:{
        VueGoodTable,
        Link,
    },
    data() {
    return {
           rows: this.broadcasts.data,
           columns: [
            {label: 'Date Sent', field: 'created_at'},
            {label: 'Title', field: 'title'},
            {label: 'Group', field: 'group'},
            {label: 'Action', field: 'action'},
            
           ]
        }
    },
    methods: {
        showDeleteAlert(id) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this broadcast?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteBroadcast(id)
                }
            });
        },
        deleteBroadcast(id){
            this.$inertia.delete(route('admin.broadcasts.destroy', id),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload(2)
                }
            });
        },
    },
    mounted() {
             this.rows = this.broadcasts.data;
    },
}
</script>

<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Attendance Submissions</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><Link :href="route('admin.attendances.index')">Attendances</Link>
                            </li>
                            <li class="breadcrumb-item active">Attendance Submissions
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Students in this Attendance:</h4>
                </div>

                <div class="card-body">

                    <vue-good-table :columns="columns" :rows="attendanceData" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'dateAdded', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                        }">


                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            

                        </template>
                    </vue-good-table>

                </div>
            </section>
        </div>


    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import moment from 'moment';
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'Lesson Attendance' },
    props: ['attendances','lessonId'],
    components: {
        Link
    },
    name: "lessonAttendances",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        attendanceData: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // { label: 'S/N', field: 'sn' },
            { label: 'First Name', field: 'first_name' },
            { label: 'Last Name', field: 'last_name' },
            { label: 'Email', field: 'email' },
            // {label: 'Status', field: 'status'},
        ],
        tableDataLoading: false,
        saving: false
    }),
    created() {
        console.log('this.attendance.data', this.attendances)
        this.attendanceData = this.attendances
        this.totalRecords = this.attendances.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.attendances.paginate';

                let { data: { data: applicationForms } } = await axios.post(route(routeName),
                    { ...this.serverParams });

                this.totalRecords = applicationForms.total;
                this.paginationOptions.perPage = applicationForms.per_page;
                this.allApplicationForms = applicationForms.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteApplicationForm(applicationFormId) {
            this.$inertia.delete(route('admin.applications.destroy', applicationFormId), {
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                (this.serverParams.page - 1)) +
                props.index + 1
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            } else {
                return '-';
            }
        },
    }
}
</script>

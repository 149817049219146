<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">STUDENT SUBMISSION</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><Link :href="route('admin.applications.submittedApplications',studentResult[0].application_id)">Submissions</Link>
                            </li>
                            <li class="breadcrumb-item active"><b>Student Submission</b>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-header table-responsive">

        <h4 class="card-title mb-1">Submission for {{ studentName }}</h4>




                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li><a data-action="expand"><i class="feather icon-maximize"></i></a></li>
                    </ul>
                </div>

                <table class="table table-bordered table-striped ">
                            <thead >
                                <tr class="p-2">
                                    <th>SN</th>
                                    <!-- <th>User</th> -->
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) of studentResult" :key="row.id">
                                    <th scope="row">{{ index + 1 }}</th>
                                    <!-- <td>{{ row.userf }} {{ row.userl }}</td> -->
                                    <td>{{ row.question_text }}</td>
                                    <td>{{ row.answer }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td v-if="applicationStatus == null" >
                                        <button :disabled="saving" @click="confirmApproval(1)" class="btn btn-lg btn-success">{{ saving == true ? 'Please wait...':'Approve' }}</button>

                                        <button :disabled="saving" @click="confirmApproval(0)" class="btn btn-lg btn-danger">{{ saving == true ? 'Please wait...':'Disapprove' }}</button>
                                    </td>
                                    <td v-else>
                                        This application has already been {{ applicationStatus.is_approved == 1 ? 'Approved' : "Disapproved" }}
                                    </td>
                                </tr>

                            </tbody>
                </table>


            </div>


    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import moment from 'moment';
import ValidationErrors from "@/Shared/ValidationErrors";
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'Submitted Applications' },
    props: ['applicationId', 'questionAndAnswers','applicationStatus'],
    components: {
        Link,ValidationErrors
    },
    name: "SubmittedApplications",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        studentResult: [],
        studentName: null,
        studentQuestionAndAnswers: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            { label: 'S/N', field: 'sn' },
            { label: 'First Name', field: 'first_name' },
            { label: 'Last Name', field: 'last_name' },
            { label: 'Email', field: 'email' },
            // {label: 'Status', field: 'status'},
            { label: 'Action', field: 'action', sortable: false },
        ],
        tableDataLoading: false,
        saving: false
    }),
    created() {
        this.studentResult = this.questionAndAnswers
        this.studentName = this.questionAndAnswers[0].first_name+' '+this.questionAndAnswers[0].last_name
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.applications.paginate';

                let { data: { data: applicationForms } } = await axios.post(route(routeName),
                    { ...this.serverParams });

                this.totalRecords = applicationForms.total;
                this.paginationOptions.perPage = applicationForms.per_page;
                this.allApplicationForms = applicationForms.data
            } catch (e) {
                console.log(e);
            }
        },

        confirmApproval(status) {
            let vm = this;
            let approvalText = status == 1 ? 'approve' : 'disapprove'
            // console.log(this.studentResult[0].user_id );
            this.$swal.fire({
                title: `Are you sure you want to ${approvalText} this Student?`,
                text: status == 1 ? "An invitation link will be sent to this user. You won't be able to revert this!" : "The student's application will be declined and he/she won't be able to continue",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, I want to ${approvalText} it!`,
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                // if (result.value) {
                //     vInstance.deleteApplicationForm(applicationFormId)
                // }
                if (result.value) {
                    vm.saving = true;
                    vm.studentResult[0].status = status
                    vm.$inertia.post(route('admin.applications.approve',vm.studentResult[0]), vm.studentResult[0], {
                    preserveScroll: true,
                        onSuccess: () => {
                            // location.reload()
                            vm.saving = false
                        },
                        onError:(e) =>{
                            vm.saving = false
                            console.log('e', e)
                        }
                    });
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                (this.serverParams.page - 1)) +
                props.index + 1
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            } else {
                return '-';
            }
        },
    }
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Modules</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><a href="/">Courses</a>
                            </li>
                            <li class="breadcrumb-item active">Modules
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Modules</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="isEditing ? updateModule() : createModule()">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Title</label>
                                        <input type="text" class="form-control"
                                               id="account-username" placeholder="Username"
                                               required=""
                                               v-model="module.title"
                                               data-validation-required-message="This title field is required">
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label class="form-label">Programme</label>
                                        <select v-model="module.programme_id" id="" class="form-control" required>
                                            <option value="" selected>Select a programme</option>
                                            <option v-for="programme in programmes" :key="programme.id" :value="programme.id">
                                                {{ programme.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!isEditing" class="row">

                            <div class="col-12 mb-3">
                                <label class="form-label">Module Image</label>

                                <file-pond
                                    v-if="true"
                                    name="test"
                                    ref="moduleImage"
                                    label-idle="Drag & drop module image"
                                    :allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    data-allow-reorder="true"
                                    data-max-file-size="3MB"
                                    :files="moduleImage"
                                />
                                <!--                            <span class="text-danger" v-if="submitted && !hasPropertyImage">This field is required.</span>-->
                            </div>
                        </div>


                        <div class="mb-3">
                            <label for="title" class="form-label">Description</label>
                            <vue-editor v-model="module.description"></vue-editor>
                        </div>

                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>

    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Create Module'},
    name: 'ModuleCreate',
    props: ['isEditing', 'moduleData', 'programmes'],
    components: {FilePond, ValidationErrors, VueEditor},
    layout: Layout,
    data() {
        return {
            module: {},
            saving: false,
            moduleImage: [],
        }
    },
    methods: {
        createModule() {
            this.makePostAndPutRequest(route('admin.modules.store'));
        },

        updateModule() {
            this.makePostAndPutRequest(route('admin.modules.update', this.module.id));
        },

        makePostAndPutRequest(route) {

            console.log('route', route)
            try {
                this.saving = true;
                const formData = new FormData();
                Object.keys(this.module).forEach(module => {
                    if (this.module[module]) {
                        formData.append(module, this.module[module]);
                    }
                });

                if (this.$refs.moduleImage && this.$refs.moduleImage.getFile()) {
                    formData.append('image', this.$refs.moduleImage.getFile().file);
                }

                let vm = this;

                this.$inertia.post(route, formData, {
                    preserveScroll: true,
                    onError: function (e) {
                    console.log('first', 'first')
                        console.error(e);
                        vm.saving = false
                    },
                    onSuccess: function () {
                        vm.saving = false
                    console.log('second', 'second')
                    },
                });

            } catch (e) {
                console.log(e);
                this.saving = false;
            }
        },
    },
    mounted() {
        if (this.isEditing) {
            this.module = this.moduleData;
        }
    }
}
</script>

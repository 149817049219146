var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"content-header-right col-md-6 col-12 mb-md-0 mb-2"},[_c('Link',{staticClass:"btn btn-outline-primary float-md-right",attrs:{"href":_vm.route('admin.courses.create')}},[_c('i',{staticClass:"feather icon-plus icon-left"}),_vm._v("\n                Add Course\n            ")])],1)]),_vm._v(" "),_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"card"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.allCourses,"line-numbers":true,"compactMode":"","search-options":{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: { field: 'dateAdded', type: 'desc' }
                    },"pagination-options":{
                    enabled: true,
                    mode: 'pages',
                    position: 'bottom',
                    perPage: 10
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'email')?_c('span',[_c('a',{attrs:{"href":`mailto:${props.row.email}`}},[_vm._v(_vm._s(props.row.email))])]):(props.column.label === 'Name')?_c('span',[_vm._v("\n                            "+_vm._s(props.row.first_name + ' ' + props.row.last_name)+"\n                        ")]):(props.column.label === 'Status')?_c('span',[_c('b',[_vm._v("Verification Status:")]),_vm._v(" "),_c('span',{staticClass:"badge",class:{ 'badge-success': props.row.is_verified,  'badge-info': !props.row.is_verified}},[_vm._v(_vm._s(props.row.is_verified ? 'Verified' : 'Not verified'))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('b',[_vm._v("Account Status:")]),_vm._v(" "),_c('span',{staticClass:"badge",class:{ 'badge-success': props.row.is_active, 'badge-danger': !props.row.is_active}},[_vm._v(_vm._s(props.row.is_active ? 'Active' : 'Deactivated'))])]):(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"dropdown"},[_c('a',{attrs:{"href":"","href":_vm.route('admin.courses.edit',props.row.id)}},[_c('i',{staticClass:"feather icon-edit",attrs:{"title":"Edit Course"}})]),_vm._v(" "),_c('a',{attrs:{"href":"","href":_vm.route('admin.courses.modules.index',props.row.id)}},[_c('i',{staticClass:"feather icon-eye",attrs:{"title":"View Modules"}})]),_vm._v(" "),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showAlert( props.row.id)}}},[_c('i',{staticClass:"feather icon-trash",attrs:{"title":"Delete Course"}})])])]):_vm._e()]}}])},[_c('div',{staticClass:"loading-container",attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('img',{attrs:{"src":"/images/Spinner.svg","alt":""}})])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('h3',{staticClass:"content-header-title mb-0"},[_vm._v("Courses")]),_vm._v(" "),_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"breadcrumb-wrapper col-12"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])]),_vm._v(" "),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Courses\n                        ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("All Courses")])])
}]

export { render, staticRenderFns }
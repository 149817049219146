<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Courses</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Courses
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.courses.create')" class="btn btn-outline-primary float-md-right">
                <i class="feather icon-plus icon-left"></i>
                Add Course
                </Link>
            </div>

        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Courses</h4>
                </div>

                <div class="card-body">

                    <!-- <vue-good-table
                        class="dataTable"
                        mode="remote"
                        :isLoading.sync="tableDataLoading"
                        :totalRows="totalRecords"
                        :pagination-options="paginationOptions"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        styleClass="vgt-table"
                        :columns="columns"
                        :rows="allCourses"
                    > -->

                        <vue-good-table :columns="columns" :rows="allCourses" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'dateAdded', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'top',
                        perPage: 10
                        }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <!-- <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span> -->
                            <span v-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>
                            <span v-else-if="props.column.label === 'Name'">
                                {{
                                    props.row.first_name + ' ' + props.row.last_name
                                }}
                            </span>
                            <span v-else-if="props.column.label === 'Status'">
                                <b>Verification Status:</b>
                                <span class="badge"
                                    :class="{ 'badge-success': props.row.is_verified, 'badge-info': !props.row.is_verified }">{{
                                        props.row.is_verified ? 'Verified' : 'Not verified'
                                    }}</span>
                                <br>
                                <b>Account Status:</b>
                                <span class="badge"
                                    :class="{ 'badge-success': props.row.is_active, 'badge-danger': !props.row.is_active }">{{
                                        props.row.is_active ? 'Active' : 'Deactivated'
                                    }}</span>
                            </span>
                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">
                                    <a id="btnSearchDrop2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        class="dropdown-toggle dropdown-menu-right"><i class="fa fa-ellipsis-v"></i></a>
                                    <span aria-labelledby="btnSearchDrop2" class="dropdown-menu mt-1 dropdown-menu-right"
                                        x-placement="bottom-end"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 19px, 0px);"
                                        x-out-of-boundaries="">
                                        <a :href="route('admin.courses.edit', props.row.id)" class="dropdown-item edit"><i
                                                class="feather icon-edit-2"></i>
                                            Edit</a>
                                        <a :href="route('admin.courses.modules.index', props.row.id)"
                                            class="dropdown-item edit"><i class="feather icon-eye"></i>
                                            View Modules</a>
                                        <div class="dropdown-divider"></div>
                                        <a href="#" @click.prevent="showAlert(props.row.id)"
                                            class="dropdown-item text-danger delete">
                                            <i class="feather icon-trash-2"></i> Delete</a>
                                    </span>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'Courses' },
    props: ['courses'],
    components: {
        Link
    },
    name: "CourseIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allCourses: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // {label: 'S/N', field: 'sn'},
            { label: 'Course Name', field: 'title' },
            { label: 'Description', field: 'short_description', sortable: false },
            { label: 'Author', field: 'author' },
            { label: 'Date', field: 'dateAdded' },
            // {label: 'Status', field: 'status'},
            { label: 'Action', field: 'action', sortable: false },
        ],
        tableDataLoading: false,
    }),
    created() {
        this.allCourses = this.courses.data
        this.totalRecords = this.courses.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.courses.paginate';

                let { data: { data: courses } } = await axios.post(route(routeName),
                    { ...this.serverParams });

                this.totalRecords = courses.total;
                this.paginationOptions.perPage = courses.per_page;
                this.allCourses = courses.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteCourse(courseId) {
            this.$inertia.delete(route('admin.courses.destroy', courseId), {
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },


        showAlert(courseId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Course?",
                text: "All histories associated with this course will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteCourse(courseId)
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>

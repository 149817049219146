<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Tutors</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Tutors
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form novalidate @submit.prevent="saveTutor">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>First Name</label>
                                        <input type="text" v-model="tutor.first_name" class="form-control"
                                               placeholder="First name"
                                               required>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>E-mail</label>
                                        <input type="email" class="form-control" v-model="tutor.email"
                                               placeholder="Email" :disabled="isEditing"
                                               required>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Twitter Url</label>
                                        <input type="text" v-model="tutor.twitter_url" class="form-control"
                                               placeholder="Twitter profile url"  >
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Last Name</label>
                                        <input type="text" v-model="tutor.last_name" class="form-control"
                                               placeholder="First name"
                                               required>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Phone</label>
                                        <input type="tel" class="form-control" v-model="tutor.phone"
                                               placeholder="Phone"
                                               required>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Linkedin profile url</label>
                                        <input type="text" class="form-control" v-model="tutor.linkedin_url"
                                               placeholder="Linkedin profile url">
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <label for="title" class="form-label">Biography</label>
                                <textarea class="form-control" v-model="tutor.biography" cols="30" rows="10">{{ tutor.biography }}</textarea>
                            </div>


                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import {VueEditor} from "vue2-editor";
import ValidationErrors from "@/Shared/ValidationErrors";

export default {
    metaInfo: {title: 'Create Tutor'},
    name: 'TutorCreate',
    props: ['isEditing', 'tutorData'],
    components: {ValidationErrors, VueEditor},
    layout: Layout,
    data() {
        return {
            tutor: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                biography: null,
            },
            saving: false
        }
    },
    methods: {
        async saveTutor() {
            let vInstance = this;
            vInstance.saving = true;

            try {
                if (this.isEditing) {
                    this.$inertia.put(route('admin.tutors.update', this.tutorData.id), {...this.tutor}, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                        onError: () => vInstance.saving = false,
                    });
                } else {
                    this.$inertia.post(route('admin.tutors.store'), this.tutor, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                        onError: () => vInstance.saving = false,
                    });

                    vInstance.saving = false;
                }
            } catch (e) {
                console.log(e);
                vInstance.saving = false
            }
        }
    },
    mounted() {
        if (this.isEditing) {
            this.tutor.first_name = this.tutorData.first_name
            this.tutor.last_name = this.tutorData.last_name
            this.tutor.email = this.tutorData.email
            this.tutor.phone = this.tutorData.phone;
            this.tutor.biography = this.tutorData.biography;
            this.tutor.twitter_url = this.tutorData.social_links.TWITTER;
            this.tutor.linkedin_url = this.tutorData.social_links.LINKEDIN;
        }
    },
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Admins</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Admins
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="users-edit">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">
                            <ul class="nav nav-tabs mb-2" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link d-flex align-items-center active" id="account-tab"
                                       data-toggle="tab" href="#account" aria-controls="account" role="tab"
                                       aria-selected="true">
                                        <i class="feather icon-user mr-25"></i><span
                                        class="d-none d-sm-block">Account</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link d-flex align-items-center" id="information-tab" data-toggle="tab"
                                       href="#information" aria-controls="information" role="tab" aria-selected="false">
                                        <i class="feather icon-info mr-25"></i><span class="d-none d-sm-block">Information</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane active" id="account" aria-labelledby="account-tab" role="tabpanel">
                                    <!-- users edit media object start -->
                                    <div class="media mb-2">
                                        <a class="mr-2" href="#">
                                            <img src="/logo.png"
                                                 alt="users avatar" class="users-avatar-shadow rounded-circle"
                                                 height="64" width="64">
                                        </a>
                                        <div class="media-body">
                                            <h4 class="media-heading">Avatar</h4>
                                            <!--                                            <div class="col-12 px-0 d-flex">-->
                                            <!--                                                <a href="#" class="btn btn-sm btn-primary mr-25">Change</a>-->
                                            <!--                                                <a href="#" class="btn btn-sm btn-secondary">Reset</a>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </div>
                                    <!-- users edit media object ends -->
                                    <!-- users edit account form start -->
                                    <form novalidate="">
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>Username</label>
                                                        <input type="text" class="form-control" placeholder="Username"
                                                               value="dean3004" required=""
                                                               data-validation-required-message="This username field is required">
                                                        <div class="help-block"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>Name</label>
                                                        <input type="text" class="form-control" placeholder="Name"
                                                               value="Dean Stanley" required=""
                                                               data-validation-required-message="This name field is required">
                                                        <div class="help-block"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>E-mail</label>
                                                        <input type="email" class="form-control" placeholder="Email"
                                                               value="deanstanley@gmail.com" required=""
                                                               data-validation-required-message="This email field is required">
                                                        <div class="help-block"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Role</label>
                                                    <select class="form-control">
                                                        <option>User</option>
                                                        <option>Staff</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select class="form-control">
                                                        <option>Active</option>
                                                        <option>Banned</option>
                                                        <option>Close</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Company</label>
                                                    <input type="text" class="form-control" placeholder="Company name">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table class="table mt-1">
                                                        <thead>
                                                        <tr>
                                                            <th>Module Permission</th>
                                                            <th>Read</th>
                                                            <th>Write</th>
                                                            <th>Create</th>
                                                            <th>Delete</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Users</td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox1"
                                                                    class="custom-control-input" checked="">
                                                                    <label class="custom-control-label"
                                                                           for="users-checkbox1"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox2"
                                                                    class="custom-control-input"><label
                                                                    class="custom-control-label"
                                                                    for="users-checkbox2"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox3"
                                                                    class="custom-control-input"><label
                                                                    class="custom-control-label"
                                                                    for="users-checkbox3"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox4"
                                                                    class="custom-control-input" checked="">
                                                                    <label class="custom-control-label"
                                                                           for="users-checkbox4"></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Articles</td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox5"
                                                                    class="custom-control-input"><label
                                                                    class="custom-control-label"
                                                                    for="users-checkbox5"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox6"
                                                                    class="custom-control-input" checked="">
                                                                    <label class="custom-control-label"
                                                                           for="users-checkbox6"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox7"
                                                                    class="custom-control-input"><label
                                                                    class="custom-control-label"
                                                                    for="users-checkbox7"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox8"
                                                                    class="custom-control-input" checked="">
                                                                    <label class="custom-control-label"
                                                                           for="users-checkbox8"></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Staff</td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox9"
                                                                    class="custom-control-input" checked="">
                                                                    <label class="custom-control-label"
                                                                           for="users-checkbox9"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox10"
                                                                    class="custom-control-input" checked="">
                                                                    <label class="custom-control-label"
                                                                           for="users-checkbox10"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox11"
                                                                    class="custom-control-input"><label
                                                                    class="custom-control-label"
                                                                    for="users-checkbox11"></label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="custom-control custom-checkbox"><input
                                                                    type="checkbox" id="users-checkbox12"
                                                                    class="custom-control-input"><label
                                                                    class="custom-control-label"
                                                                    for="users-checkbox12"></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                                <button type="submit"
                                                        class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">Save
                                                    changes
                                                </button>
                                                <button type="reset" class="btn btn-light">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                    <!-- users edit account form ends -->
                                </div>
                                <div class="tab-pane" id="information" aria-labelledby="information-tab"
                                     role="tabpanel">
                                    <!-- users edit Info form start -->
                                    <form novalidate="">
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <h5 class="mb-1"><i class="feather icon-link mr-25"></i>Social Links
                                                </h5>
                                                <div class="form-group">
                                                    <label>Twitter</label>
                                                    <input class="form-control" type="text"
                                                           value="https://www.twitter.com/">
                                                </div>
                                                <div class="form-group">
                                                    <label>Facebook</label>
                                                    <input class="form-control" type="text"
                                                           value="https://www.facebook.com/">
                                                </div>
                                                <div class="form-group">
                                                    <label>Google+</label>
                                                    <input class="form-control" type="text">
                                                </div>
                                                <div class="form-group">
                                                    <label>LinkedIn</label>
                                                    <input class="form-control" type="text">
                                                </div>
                                                <div class="form-group">
                                                    <label>Instagram</label>
                                                    <input class="form-control" type="text"
                                                           value="https://www.instagram.com/">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                                                <h5 class="mb-1"><i class="feather icon-user mr-25"></i>Personal Info
                                                </h5>
                                                <div class="form-group">
                                                    <div class="controls position-relative">
                                                        <label>Birth date</label>
                                                        <input class="form-control" type="date">
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <select class="form-control" id="accountSelect">
                                                        <option>USA</option>
                                                        <option>India</option>
                                                        <option>Canada</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Languages</label>
                                                    <select class="form-control select2-hidden-accessible"
                                                            id="users-language-select2" multiple=""
                                                            data-select2-id="users-language-select2" tabindex="-1"
                                                            aria-hidden="true">
                                                        <option value="English" selected="" data-select2-id="2">
                                                            English
                                                        </option>
                                                        <option value="Spanish">Spanish</option>
                                                        <option value="French">French</option>
                                                        <option value="Russian">Russian</option>
                                                        <option value="German">German</option>
                                                        <option value="Arabic" selected="" data-select2-id="3">Arabic
                                                        </option>
                                                        <option value="Sanskrit">Sanskrit</option>
                                                    </select><span
                                                    class="select2 select2-container select2-container--default"
                                                    dir="ltr" data-select2-id="1" style="width: 100%;"><span
                                                    class="selection"><span
                                                    class="select2-selection select2-selection--multiple"
                                                    role="combobox" aria-haspopup="true" aria-expanded="false"
                                                    tabindex="-1" aria-disabled="false"><ul
                                                    class="select2-selection__rendered"><li
                                                    class="select2-selection__choice" title="English"
                                                    data-select2-id="4"><span class="select2-selection__choice__remove"
                                                                              role="presentation">×</span>English</li><li
                                                    class="select2-selection__choice" title="Arabic"
                                                    data-select2-id="5"><span class="select2-selection__choice__remove"
                                                                              role="presentation">×</span>Arabic</li><li
                                                    class="select2-search select2-search--inline"><input
                                                    class="select2-search__field" type="search" tabindex="0"
                                                    autocomplete="off" autocorrect="off" autocapitalize="none"
                                                    spellcheck="false" role="searchbox" aria-autocomplete="list"
                                                    placeholder="" style="width: 0.75em;"></li></ul></span></span><span
                                                    class="dropdown-wrapper" aria-hidden="true"></span></span>
                                                </div>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>Phone</label>
                                                        <input type="text" class="form-control" required=""
                                                               placeholder="Phone number" value="(+656) 254 2568"
                                                               data-validation-required-message="This phone number field is required">
                                                        <div class="help-block"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>Address</label>
                                                        <input type="text" class="form-control" placeholder="Address"
                                                               data-validation-required-message="This Address field is required">
                                                        <div class="help-block"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Website</label>
                                                    <input type="text" class="form-control"
                                                           placeholder="Website address">
                                                </div>
                                                <div class="form-group">
                                                    <label>Favourite Music</label>
                                                    <select class="form-control select2-hidden-accessible"
                                                            id="users-music-select2" multiple=""
                                                            data-select2-id="users-music-select2" tabindex="-1"
                                                            aria-hidden="true">
                                                        <option value="Rock">Rock</option>
                                                        <option value="Jazz" selected="" data-select2-id="7">Jazz
                                                        </option>
                                                        <option value="Disco">Disco</option>
                                                        <option value="Pop">Pop</option>
                                                        <option value="Techno">Techno</option>
                                                        <option value="Folk" selected="" data-select2-id="8">Folk
                                                        </option>
                                                        <option value="Hip hop">Hip hop</option>
                                                    </select><span
                                                    class="select2 select2-container select2-container--default"
                                                    dir="ltr" data-select2-id="6" style="width: 100%;"><span
                                                    class="selection"><span
                                                    class="select2-selection select2-selection--multiple"
                                                    role="combobox" aria-haspopup="true" aria-expanded="false"
                                                    tabindex="-1" aria-disabled="false"><ul
                                                    class="select2-selection__rendered"><li
                                                    class="select2-selection__choice" title="Jazz"
                                                    data-select2-id="9"><span class="select2-selection__choice__remove"
                                                                              role="presentation">×</span>Jazz</li><li
                                                    class="select2-selection__choice" title="Folk" data-select2-id="10"><span
                                                    class="select2-selection__choice__remove"
                                                    role="presentation">×</span>Folk</li><li
                                                    class="select2-search select2-search--inline"><input
                                                    class="select2-search__field" type="search" tabindex="0"
                                                    autocomplete="off" autocorrect="off" autocapitalize="none"
                                                    spellcheck="false" role="searchbox" aria-autocomplete="list"
                                                    placeholder="" style="width: 0.75em;"></li></ul></span></span><span
                                                    class="dropdown-wrapper" aria-hidden="true"></span></span>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Favourite movies</label>
                                                    <select class="form-control select2-hidden-accessible"
                                                            id="users-movies-select2" multiple=""
                                                            data-select2-id="users-movies-select2" tabindex="-1"
                                                            aria-hidden="true">
                                                        <option value="The Dark Knight" selected=""
                                                                data-select2-id="12">The Dark Knight
                                                        </option>
                                                        <option value="Harry Potter" selected="" data-select2-id="13">
                                                            Harry Potter
                                                        </option>
                                                        <option value="Airplane!">Airplane!</option>
                                                        <option value="Perl Harbour">Perl Harbour</option>
                                                        <option value="Spider Man">Spider Man</option>
                                                        <option value="Iron Man" selected="" data-select2-id="14">Iron
                                                            Man
                                                        </option>
                                                        <option value="Avatar">Avatar</option>
                                                    </select><span
                                                    class="select2 select2-container select2-container--default"
                                                    dir="ltr" data-select2-id="11" style="width: 100%;"><span
                                                    class="selection"><span
                                                    class="select2-selection select2-selection--multiple"
                                                    role="combobox" aria-haspopup="true" aria-expanded="false"
                                                    tabindex="-1" aria-disabled="false"><ul
                                                    class="select2-selection__rendered"><li
                                                    class="select2-selection__choice" title="The Dark Knight
                                            " data-select2-id="15"><span class="select2-selection__choice__remove"
                                                                         role="presentation">×</span>The Dark Knight
                                            </li><li class="select2-selection__choice" title="Harry Potter"
                                                     data-select2-id="16"><span
                                                    class="select2-selection__choice__remove"
                                                    role="presentation">×</span>Harry Potter</li><li
                                                    class="select2-selection__choice" title="Iron Man"
                                                    data-select2-id="17"><span class="select2-selection__choice__remove"
                                                                               role="presentation">×</span>Iron Man</li><li
                                                    class="select2-search select2-search--inline"><input
                                                    class="select2-search__field" type="search" tabindex="0"
                                                    autocomplete="off" autocorrect="off" autocapitalize="none"
                                                    spellcheck="false" role="searchbox" aria-autocomplete="list"
                                                    placeholder="" style="width: 0.75em;"></li></ul></span></span><span
                                                    class="dropdown-wrapper" aria-hidden="true"></span></span>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                                <button type="submit"
                                                        class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">Save
                                                    changes
                                                </button>
                                                <button type="reset" class="btn btn-light">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                    <!-- users edit Info form ends -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'

export default {
    metaInfo: {title: 'Create Admin'},
    name: 'DriverShow',
    props: ['isEditing', 'adminData'],
    layout: Layout,
    data() {
        return {
            admin: this.$inertia.form({
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                role: null,
            }),
            saving: false
        }
    },
    methods: {
        async saveAdmin() {
            let vInstance = this;
            vInstance.saving = true;

            try {
                if (this.isEditing) {
                    this.admin.put(route('admins.update', this.adminData.id), {...this.admin}, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                    });
                } else {
                    this.admin.post(route('admin.admins.store'), this.admin, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                    });

                    vInstance.saving = false;
                }
            } catch (e) {
                console.log(e);
                vInstance.saving = false
            }
        }
    },
    mounted() {
        if (this.isEditing) {
            this.admin.first_name = this.adminData.first_name
            this.admin.last_name = this.adminData.last_name
            this.admin.email = this.adminData.email
            this.admin.phone_number = this.adminData.phone_number
            this.admin.role = this.adminData.role
        }
    },
}
</script>

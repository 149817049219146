<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Tutors</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Tutor
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Tutors</h4>
                </div>

                <div class="card-body">
                    <!--
                    <vue-good-table
                        class="dataTable"
                        mode="remote"
                        :isLoading.sync="tableDataLoading"
                        :totalRows="totalRecords"
                        :pagination-options="paginationOptions"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        styleClass="vgt-table"
                        :columns="columns"
                        :rows="allTutors"
                    > -->

                    <div v-if="showFilter">
                        <h4>Filters</h4>
                        <div class="row mb-3">
                            <div class="col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="courses">Course</label>
                                    <select v-model="filter.course" class="form-control" id="course">
                                        <option value="" selected>Select a course</option>
                                        <option
                                            v-for="course in courses"
                                            :value="course.id"
                                        >
                                            {{ course.title }}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <!-- <div class="col-md-3 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="programmes">Programme</label>
                                    <select v-model="filter.programme" class="form-control" id="programme">
                                        <option value="" selected>Select a programme</option>
                                        <option
                                            v-for="programme in programmes"
                                            :value="programme.id"
                                        >
                                            {{ programme.name }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->

                            <div class="col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label for="statuss">Activation Status</label>
                                    <select v-model="filter.status" class="form-control" id="status">
                                        <option value="" selected>Select a status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inctive</option>
                                    </select>
                                </div>
                            </div>


                            <div class="col-md-3 col-sm-6 col-6">
                                <button class="btn btn-dark mt-2" v-on:click="getFilteredTutors()">Filter</button>
                                <button class="btn btn-dark mt-2" v-on:click="resetTutors()">Reset Table</button>
                            </div>
                        </div>
                    </div>

                    <vue-good-table :columns="columns" :rows="allTutors" :line-numbers="true" compactMode :search-options="{
                                                                enabled: true,
                                                                skipDiacritics: true,
                                                                initialSortBy: { field: 'dateJoined', type: 'desc' }
                                                                }" :pagination-options="{
                                                                                            enabled: true,
                                                                                            mode: 'pages',
                                                                                            position: 'bottom',
                                                                                            perPage: 10
                                                                                        }">


                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <!-- <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span> -->
                            <span v-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>
                            <span v-else-if="props.column.label === 'Name'">
                                {{props.row.first_name + ' ' + props.row.last_name}}
                            </span>
                            <span v-else-if="props.column.field === 'courses'">
                                <span v-for="(course, index) in props.row.courses" :key="index">
                                {{ course }}<span v-show="index !== props.row.courses.length - 1">,</span>
                            </span>
                            </span>

                            <span v-else-if="props.column.label === 'Activation Status'">
                                <span class="badge"
                                    :class="{ 'badge-success': props.row.status == 1,  'badge-danger': props.row.status == 0 }">
                                    {{ props.row.status == 1 ? "Active":"Deactivated" }}
                                </span>
                            </span>

                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">
                                <a href="" :href="route('admin.tutors.edit',props.row.id)"><i title="Edit Tutor" class="feather icon-edit"></i></a>
                                <a  href=""  @click.prevent="showUpdateAlert( props.row.id, props.row.status)"><i  :title="props.row.status == 1 ? 'Disable':'Activate'" class="fa fa-user"></i></a>
                                </span>

                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Tutors'},
    props: ['tutors', 'courses', 'programmes'],
    name: "TutorIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        showFilter: true,
        isLoadingFilter: false,
        allTutors: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'Name', field: 'name'},
            {label: 'Email', field: 'email'},
            {label: 'Phone', field: 'phone'},
            {label: 'Date Joined', field: 'dataJoined'},
            {label: 'courses', field: 'courses'},
            {label: 'Activation Status', field: 'status'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    created() {
        console.log("this.tutors", this.tutors);
        this.allTutors = this.tutors
        this.totalRecords = this.tutors.length
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.tutors.paginate';

                let {data: {data: tutors}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = tutors.length;
                this.paginationOptions.perPage = tutors.per_page;
                this.allTutors = tutors
            } catch (e) {
                console.log(e);
            }
        },

        deleteTutor(id){
            this.$inertia.delete(route('admin.tutors.destroy', id),{
                preserveScroll: true,
            });

        },

        showAlert(tutorId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Tutor?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteTutor(tutorId)
                }
            });
        },

        showUpdateAlert(id, status) {
            let vInstance = this;

            let action = status ? 'deactivate' : 'activate'
            this.$swal.fire({
                title: `Are you sure you want to ${action} tutor's account?`,
                // text: "All histories associated with this course will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${action} it!`,
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.updateStatus(id)
                }
            });
        },
        updateStatus(id){
            this.$inertia.get(route('admin.students.updateStudentStatus', id),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },

        async getFilteredTutors() {
            try {
                let routeName = 'admin.tutors.filter';

                axios.get(route(routeName), { params: this.filter })
                .then(response => {
                    let {data:{data: tutors}} = response

                    this.allTutors = tutors.data
                    console.log('tutors.data', tutors)
                })
                .catch(error => {
                    console.error(error);
                });
            } catch (e) {
                console.log(e);
            }
        },



        async resetTutors() {
            this.allTutors = this.tutors
        },
    }
}
</script>

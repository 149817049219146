<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Programmes</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Programmes
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Programme</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="saveProgramme">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Programme Name</label>
                                        <input type="text" class="form-control"
                                               id="account-username" placeholder="Programme name"
                                               required=""
                                               v-model="programme.name"
                                               data-validation-required-message="This name field is required">
                                        <div class="help-block"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="min-age">Minimum Age</label>
                                    <input type="number" class="form-control" id="min-age" placeholder="Minimum Age"
                                        v-model="programme.min_age">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="max-age">Maximum Age</label>
                                    <input type="number" class="form-control" id="max-age" placeholder="Maximum Age"
                                        v-model="programme.max_age">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="gender">Gender</label>
                                    <select class="form-control" id="gender" v-model="programme.gender">
                                        <option value="All">All</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Create Programme'},
    name: 'ProgrammeCreate',
    props: ['isEditing', 'programmeData', 'programmes'],
    components: {FilePond, ValidationErrors, VueEditor},
    layout: Layout,
    data() {
        return {
            programme: {},
            saving: false,
            programmeImage: [],
        }
    },
    methods: {
        async saveProgramme() {
            let vInstance = this;
            vInstance.saving = true;

            try {
                if (this.isEditing) {
                    this.$inertia.put(route('admin.programmes.update', this.programmeData.id), {...this.programme}, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                        onError: () => vInstance.saving = false,
                    });
                } else {
                    this.$inertia.post(route('admin.programmes.store'), this.programme, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                        onError: () => vInstance.saving = false,
                    });

                    vInstance.saving = false;
                }
            } catch (e) {
                console.log(e);
                vInstance.saving = false
            }
        }
    },
    created() {

    },
    mounted() {
        if (this.isEditing) {
            this.programme = this.programmeData;
        }
    }
}
</script>

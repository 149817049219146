<template>
    <div>
        <div v-if="$page.props.errors && show" class="alert alert-danger" :class="{show: show}">
            <ul v-for="error in $page.props.errors" :key="error.id">
                <li> {{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "ValidationErrors",
    data: () => {
        return {
            show: false
        }
    },
    watch: {
        show() {
            setTimeout(() => {
                this.show = this.show ? !this.show : this.show
            }, 20000)
        },
        '$page.props.errors': {
            handler() {
                this.show = true
            },
            deep: true,
        },
    },
}
</script>

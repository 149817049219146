<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Videos</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="/courses">Courses</a>
                            </li>
                            <li class="breadcrumb-item active">Videos</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <button
                    @click.prevent="addVideoModal"
                    data-toggle="modal"
                    data-target="#faq-modal"
                    type="button"
                    class="btn btn-outline-primary float-md-right"
                >
                    <i class="feather icon-plus icon-left"></i>
                    Add Video
                </button>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Assessments' Videos</h4>
                </div>

                <div class="card-body">
                    <vue-good-table
                        :columns="columns"
                        :rows="videos"
                        :line-numbers="true"
                        compactMode
                        :search-options="{
                            enabled: true,
                            skipDiacritics: true,
                            initialSortBy: {
                                field: 'dataJoined',
                                type: 'desc',
                            },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'bottom',
                            perPage: 10,
                        }"
                    >
                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="" />
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'sn'">
                                {{ serialNo(props) }}
                            </span>
                            <span
                                v-else-if="props.column.label === 'Video Link'"
                            >
                                <a
                                    target="_blank"
                                    :href="`${props.row.video_link}`"
                                    >{{ props.row.video_link }}
                                </a>
                            </span>
                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">

                                    <!-- <a
                                        href=""
                                        @click.prevent="
                                            editVideoModal(props.row.id)
                                        "
                                        ><i
                                            title="Edit Video"
                                            class="feather icon-edit"
                                        ></i
                                    ></a> -->
                                    <a
                                        href=""
                                        @click.prevent="showAlert(props.row.id)"
                                        ><i
                                            title="Delete Video"
                                            class="feather icon-trash"
                                        ></i
                                    ></a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>

        <div
            class="modal fade add-video-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="addVideo" enctype="multipart/form-data" method="post">
                        <div class="modal-header">
                            <h4 id="myModalLabel1" class="modal-title">
                                Add Video
                            </h4>
                            <button
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                class="close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <validation-errors></validation-errors>
                            <div class="form-group">
                                <label>Video</label>
                                <input
                                    type="file"
                                    accept="video/*"
                                    required="required"
                                    class="form-control"
                                    ref="video"
                                    @change="setVideoFile"
                                />
                            </div>
                            <div class="form-group">
                                <label>Course</label>
                                <select
                                    class="form-control"
                                    v-model="video.course_id"
                                    required
                                    v-if="!this.videoEdit.length > 0"
                                >
                                    <option disabled selected>
                                        Select course
                                    </option>
                                    <option
                                        v-for="course in courses"
                                        :value="course.id"
                                    >
                                        {{ course.title }}
                                    </option>
                                </select>

                                <select

                                    v-if="this.videoEdit.length > 0"
                                    class="form-control"
                                    v-model="videoEdit.course_id"
                                    required
                                >
                                    <option disabled selected>
                                        Select course
                                    </option>
                                    <option
                                        v-for="course in courses"
                                        :value="course.id"
                                    >
                                        {{ course.title }}
                                    </option>
                                </select>
                            </div>


                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Save
                                <i
                                    class="fa fa-spinner fa-spin"
                                    v-if="saving"
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="modal fade edit-video-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <!-- <validation-errors></validation-errors> -->
                    <!-- {{ errors.title }} -->
                    <form @submit.prevent="editVideo(videoEdit)">
                        <div class="modal-header">
                            <h4 id="myModalLabel1" class="modal-title">
                                Edit Video
                            </h4>
                            <button
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                class="close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Video</label>
                                <input
                                    type="file"
                                    accept="video/*"
                                    required="required"
                                    class="form-control"
                                    ref="videoEdit"
                                    @change="setVideoFile"
                                />
                            </div>


                            <div class="form-group">
                                <label>Course</label>
                                <select
                                    class="form-control"
                                    v-model="videoEdit.course_id"
                                    required
                                >
                                    <option disabled selected>
                                        Select course
                                    </option>
                                    <option
                                        v-for="course in courses"
                                        :value="course.id"
                                    >
                                        {{ course.title }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Update
                                <i
                                    class="fa fa-spinner fa-spin"
                                    v-if="saving"
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import ValidationErrors from "../../Shared/ValidationErrors.vue";

export default {
    metaInfo: { title: "Assessments' Videos" },
    props: ["videos", "courses"],
    name: "VideoIndex",
    components: { ValidationErrors },
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allVideos: [],
        allCourses: [],
        video: {},
        course: {},
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10,
        },
        paginationOptions: {
            enabled: true,
            mode: "pages",
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            { label: "Course", field: "course.title" },
            { label: "Video Link", field: "video" },
            { label: "Action", field: "action", sortable: false },
        ],
        tableDataLoading: false,
        videoEdit: [],
        saving: false,
        reloadData: null,
    }),
    created() {},
    methods: {
        addVideoModal() {
            $(".add-video-modal").modal("show");
        },

        setVideoFile(){
            console.log('this.$refs.video.files[0]', this.$refs.video.files[0])
            this.video.video = this.$refs.video.files[0];
        },

        editVideoModal(videoId) {
            this.videoEdit = this.videos.find((video) => video.id === videoId);
            $(".add-video-modal").modal("show");
            // $(".edit-video-modal").modal("show");
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            };
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = "admin.videos.paginate";

                let {
                    data: { data: videos },
                } = await axios.post(route(routeName), {
                    ...this.serverParams,
                });

                this.totalRecords = videos.total;
                this.paginationOptions.perPage = videos.per_page;
                this.allVideos = videos.data;
            } catch (e) {
                console.log(e);
            }
        },

        serialNo(props) {
            return this.serverParams.page === 1
                ? props.index + 1
                : this.serverParams.perPage * (this.serverParams.page - 1) +
                      props.index +
                      1;
        },

        deleteVideo(videoId) {

            this.$inertia.delete(route("admin.preliminaryAssessments.videos.destroy", videoId), {
                preserveScroll: true,
                onSuccess: () => {
                    // location.reload();
                },
            });
        },

        addVideo() {
            console.log('this.video', this.video)
            try {
                this.$inertia.post(
                    route("admin.preliminaryAssessments.videos.store"),
                    this.video,
                    {
                        preserveScroll: true,
                        onError: function (e) {
                            console.error(e);
                            vm.saving = false;
                        },
                        onSuccess: function (e) {
                            vm.saving = false;
                            vm.videoEdit = [];
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        },
                    }
                );
                console.log('"first"', "first")
            } catch (error) {
                console.log("error", error);
            }
        },

        editVideo(videdo) {
            const { id, course_id } = videdo;

            let videoData = { id, course_id };
            videoData.video = this.$refs.videoEdit.files[0]
            $(".edit-video-modal").modal("hide");
            let vm = this;
            console.log("videoData", videoData);
            this.$inertia.post(
                route("admin.preliminaryAssessments.videos.store", videoData),
                {
                    preserveScroll: true,
                    onError: function (e) {
                        console.error(e);
                        vm.saving = false;
                    },
                    onSuccess: function () {
                        vm.saving = false;
                        $(".edit-video-modal").modal("hide");
                        this.allVideos = this.videos.data;
                        this.totalRecords = this.videos.total;
                    },
                }
            );
        },

        showAlert(videoId) {
            let vInstance = this;

            this.$swal
                .fire({
                    title: "Are you sure you want to delete this Video?",
                    text: "All histories associated with this video will be lost. You won't be able to revert this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    confirmButtonClass: "btn btn-primary mr-3",
                    cancelButtonClass: "btn btn-danger ml-1",
                    buttonsStyling: false,
                })
                .then(function (result) {
                    if (result.value) {
                        vInstance.deleteVideo(videoId);
                    }
                });
        },
    },
};
</script>

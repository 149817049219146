<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Admins</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Admins
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <form novalidate @submit.prevent="saveAdmin">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>First Name</label>
                                        <input type="text" v-model="admin.first_name" class="form-control"
                                               placeholder="First name"
                                               required>
                                        <div v-if="admin.errors.first_name" class="text-danger text-small">
                                            {{ admin.errors.first_name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>E-mail</label>
                                        <input type="email" class="form-control" v-model="admin.email"
                                               placeholder="Email"
                                               required>
                                        <div v-if="admin.errors.email" class="text-danger text-small">
                                            {{ admin.errors.email }}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Last Name</label>
                                        <input type="text" v-model="admin.last_name" class="form-control"
                                               placeholder="First name"
                                               required>
                                        <div v-if="admin.errors.last_name" class="text-danger text-small">
                                            {{ admin.errors.last_name }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label class="form-label">Role</label>
                                        <select v-model="admin.role" id="" class="form-control" required>
                                            <option value="" selected>Select a user role</option>
                                            <option v-for="role in roles" :key="role.id" :value="role.name">
                                                {{ role.name }}
                                            </option>
                                        </select>
                                        <span v-if="admin.errors.role"
                                              class="text-danger text-small">{{ admin.errors.role }}</span>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner fa-spin" v-if="saving"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'

export default {
    metaInfo: {title: 'Create Admin'},
    name: 'AdminCreate',
    props: ['isEditing', 'adminData', 'roles'],
    layout: Layout,
    data() {
        return {
            admin: this.$inertia.form({
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                role: null,
            }),
            saving: false
        }
    },
    methods: {
        async saveAdmin() {
            this.saving = true;
            if (this.isEditing) {
                this.admin.put(route('admin.admins.update', this.adminData.id), {...this.admin}, {
                    preserveScroll: true,
                    onSuccess: () => this.saving = false,
                    onError: () => {
                        this.saving = false;
                    }
                });
            } else {
                this.admin.post(route('admin.admins.store'), this.admin, {
                    preserveScroll: true,
                    onSuccess: () => this.saving = false,
                    onError: () => {
                        this.saving = false;
                    }
                });
            }

            this.saving = false;
        }
    },
    mounted() {
        if (this.isEditing) {
            this.admin.first_name = this.adminData.first_name
            this.admin.last_name = this.adminData.last_name
            this.admin.email = this.adminData.email
            this.admin.phone_number = this.adminData.phone_number
            this.admin.role = this.adminData.role
        }
    },
}
</script>

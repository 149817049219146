<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Quiz Answers</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Answers

                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4> Students</h4>
                </div>

                <div class="card-body">

                    <vue-good-table :columns="columns" :rows="submissions" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                        }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <span v-if="props.column.label === 'Course'">
                                {{ props.row.user.course?.title  == "" ? '-': props.row.user.course?.title }}
                            </span>

                            <span v-else-if="props.column.label === 'Student'">
                                {{ `${props.row.user.first_name} ${props.row.user.last_name}`}}
                            </span>

                            <span v-else-if="props.column.label === 'Programme'">
                                {{ props.row.user.course?.programme?.name == "" ? '-': props.row.user.course?.programme?.name }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>



    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';



export default {
    metaInfo: {title: 'Assessment Submissions'},
    props: ['submissions','id'],
    components: {
        Link
    },
    name: "AssessmentSubmissions",
    layout: Layout,

    data: () => ({
        search: null,
        pageType: null,
        filter: {},
        isLoadingFilter: false,
        allAnswers: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'Student', field: 'user.full_name'},
            {label: 'Email', field: 'user.email'},
            {label: 'Course', field: 'user.course.title'},
            {label: 'Programme', field: 'user.course.programme.name'},
            {label: 'Score', field: 'student_score'},
        ],
        tableDataLoading: false,
        saving: false,
        quizEdit: [],

    }),
    created() {
        this.submissions = this.submissions
        this.totalRecords = this.submissions.length
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.submission.paginate';

                let {data:{data: submission}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = submission.total;
                this.paginationOptions.perPage = submission.per_page;
                this.allAnswers = submission.data
            } catch (e) {
                console.log(e);
            }
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    },
}
</script>

<template>
    <!-- Begin page -->
    <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
            <flash-messages></flash-messages>
            <slot/>
        </div>
    </div>

</template>

<script>
import FlashMessages from '@/Shared/FlashMessages'

export default {
    name: "Layout",
    components: {
        FlashMessages
    },
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Modules</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><a href="/courses">Courses</a>
                            </li>
                            <li class="breadcrumb-item active">Modules
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <button @click.prevent="addModuleModal" data-toggle="modal" data-target="#faq-modal"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add Module
                </button>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Modules in <b>{{ course?.title }}</b></h4>
                </div>

                <div class="card-body">

                    <vue-good-table
                        class="dataTable"
                        mode="remote"
                        :isLoading.sync="tableDataLoading"
                        :totalRows="totalRecords"
                        :pagination-options="paginationOptions"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        styleClass="vgt-table"
                        :columns="columns"
                        :rows="allModules"
                    >

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span>
                            <span v-else-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>
                            <span v-else-if="props.column.label === 'Name'">
                                {{
                                    props.row.first_name + ' ' + props.row.last_name
                                }}
                            </span>
                            <span v-else-if="props.column.label === 'Assigned Tutor'">
                                {{
                                   props.row.tutor !== null ? props.row.tutor?.first_name + ' ' + props.row.tutor?.last_name : ''
                                }}
                            </span>
                            <span v-else-if="props.column.label === 'Status'">
                                    <b>Verification Status:</b>
                                    <span class="badge"
                                          :class="{ 'badge-success': props.row.is_verified,  'badge-info': !props.row.is_verified}">{{
                                            props.row.is_verified ? 'Verified' : 'Not verified'
                                        }}</span>
                                    <br>
                                    <b>Account Status:</b>
                                    <span class="badge"
                                          :class="{ 'badge-success': props.row.is_active, 'badge-danger': !props.row.is_active}">{{
                                            props.row.is_active ? 'Active' : 'Deactivated'
                                        }}</span>
                                </span>
                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">
									<a id="btnSearchDrop2" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false" class="dropdown-toggle dropdown-menu-right"><i
                                        class="fa fa-ellipsis-v"></i></a>
                                    <span aria-labelledby="btnSearchDrop2"
                                          class="dropdown-menu mt-1 dropdown-menu-right"
                                          x-placement="bottom-end"
                                          style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 19px, 0px);"
                                          x-out-of-boundaries="">
                                        <a href="" @click.prevent="editModuleModal(props.row.id)" data-toggle="modal"
                                           data-target="#faq-modal"
                                           class="dropdown-item edit"><i class="feather icon-edit-2"></i>
                                            Edit</a>
                                        <a :href="route('admin.lessons.index',props.row.id)"
                                        class="dropdown-item edit"><i class="feather icon-eye-2"></i>
                                        View Lessons</a>

                                        <!--                                        <a href="#"-->
                                        <!--                                           @click.prevent="updateStatus(props.row.id, props.row.is_active)"-->
                                        <!--                                           class="dropdown-item">-->
                                        <!--                                                      <i class="feather icon-check"></i>-->
                                        <!--                                                {{ !props.row.is_active ? 'Activate ' : 'Deactivate ' }} Account-->
                                        <!--                                            </a>-->
                                        <div class="dropdown-divider"></div>
                                        <a href="#" @click.prevent="showAlert( props.row.id)"
                                           class="dropdown-item text-danger delete">
                                            <i class="feather icon-trash-2"></i> Delete</a>
                                    </span>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>

        <div class="modal fade add-module-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="addModule">
                        <div class="modal-header"><h4 id="myModalLabel1" class="modal-title">Add Module</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <validation-errors></validation-errors>
                            <div class="form-group"><label>Module Name</label>
                                <input type="text" required="required"
                                       class="form-control" v-model="module.title">
                                <!--                                <div v-if="module.errors.name" class="text-danger text-small">-->
                                <!--                                    {{ module.errors.name }}-->
                                <!--                                </div>-->
                            </div>
                            <div class="form-group"><label>Position</label>
                                <input type="number" class="form-control" min="1"  v-model="module.position" required>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Save <i class="fa fa-spinner fa-spin" v-if="saving"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade edit-module-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!-- <validation-errors></validation-errors> -->
                    <!-- {{ errors.title }} -->
                    <form @submit.prevent="editModule(moduleEdit)">
                        <div class="modal-header"><h4 id="myModalLabel1" class="modal-title">Edit Module</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group"><label>Module Name</label>
                                <input type="text" required="required"
                                       class="form-control" v-model="moduleEdit.title">
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Update <i class="fa fa-spinner fa-spin" v-if="saving"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import ValidationErrors from "../../Shared/ValidationErrors.vue";

export default {
    metaInfo: {title: 'Modules'},
    props: ['modules', 'course'],
    name: "ModulesIndex",
    components: { ValidationErrors},
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allModules: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'S/N', field: 'sn'},
            {label: 'Module Name', field: 'title'},
            {label: 'Position', field: 'position'},
            {label: 'Date', field: 'dateAdded'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        module: {},
        moduleEdit: [],
        tableDataLoading: false,
        saving: false,
        reloadData: null,
    }),
    created() {
        console.log('this.modules', this.modules)
        this.allModules = this.modules
        this.totalRecords = this.modules.total
    },
    methods: {
        addModuleModal() {
            $('.add-module-modal').modal('show');
        },

        editModuleModal(moduleId) {
            this.moduleEdit = this.allModules.find(module => module.id === moduleId);
            $('.edit-module-modal').modal('show');
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.modules.paginate';

                let {data: {data: modules}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = modules.total;
                this.paginationOptions.perPage = modules.per_page;
                this.allModules = modules.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteModule(course, moduleId) {
            this.$inertia.delete(route('admin.modules.destroy', course.id), {
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },

        addModule() {
            let vm = this;
            this.reloadData = Math.floor(Math.random() * 10) + 1;
            this.$inertia.post(route('admin.courses.modules.store', this.course.id), this.module, {
                preserveScroll: true,
                onError: function (e) {
                    console.error(e);
                    vm.saving = false
                },
                onSuccess: function (e) {
                    console.log(e);
                    vm.saving = false
                    vm.moduleEdit = [];
                    // vm.user_id = null;
                    // $('.add-module-modal').modal('hide')
                    this.allModules = this.modules.data
                    this.totalRecords = this.modules.total
                },
            });
        },

        editModule(moduleId) {
            $('.edit-module-modal').modal('hide')
            let vm = this;
            this.$inertia.put(route('admin.courses.modules.update', [this.course.id,moduleId]), moduleId, {
                preserveScroll: true,
                onError: function (e) {
                    console.error(e);
                    vm.saving = false
                },
                onSuccess: function () {
                    vm.saving = false;
                    $('.edit-module-modal').modal('hide')
                    this.allModules = this.modules.data
                    this.totalRecords = this.modules.total
                },
            });
        },


        showAlert(courseId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Module?",
                text: "All histories associated with this module will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteModule(courseId)
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    },
    watch: {
        reloadData(newValue, oldValue) {
            if (newValue) {
                  $('.add-module-modal').modal('hide')
                 this.allModules = this.$inertia.get(route('admin.courses.modules.index', this.course.id));
            }
        },

    }
}
</script>

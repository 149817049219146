var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"card"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.allAnswers,"line-numbers":true,"compactMode":"","search-options":{
                    enabled: true,
                    skipDiacritics: true
                    },"pagination-options":{
                    enabled: true,
                    mode: 'pages',
                    position: 'bottom',
                    perPage: 10
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label === 'Course')?_c('span',[_vm._v("\n                            "+_vm._s(props.row.student.course?.title  == "" ? '-': props.row.student.course?.title)+"\n                        ")]):(props.column.label === 'Programme')?_c('span',[_vm._v("\n                            "+_vm._s(props.row.student.course?.programme?.name == "" ? '-': props.row.student.course?.programme?.name)+"\n                        ")]):(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle dropdown-menu-right",attrs:{"id":"btnSearchDrop2","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"fa fa-ellipsis-v"})]),_vm._v(" "),_c('span',{staticClass:"dropdown-menu mt-1 dropdown-menu-right",staticStyle:{"position":"absolute","will-change":"transform","top":"0px","left":"0px","transform":"translate3d(0px, 19px, 0px)"},attrs:{"aria-labelledby":"btnSearchDrop2","x-placement":"bottom-end","x-out-of-boundaries":""}},[_c('Link',{staticClass:"dropdown-item edit",attrs:{"href":_vm.route('admin.quizzes.getStudentQuizQuestionAndAnswers',[props.row.user_id,_vm.id])}},[_c('i',{staticClass:"feather icon-edit-2"}),_vm._v("\n                                        View Submission:\n                                    ")])],1)])]):_vm._e()]}}])},[_c('div',{staticClass:"loading-container",attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('img',{attrs:{"src":"/images/Spinner.svg","alt":""}})])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('h3',{staticClass:"content-header-title mb-0"},[_vm._v("Quiz Answers")]),_vm._v(" "),_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"breadcrumb-wrapper col-12"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])]),_vm._v(" "),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Answers\n\n                        ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" Students")])])
}]

export { render, staticRenderFns }
<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Cohorts</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Cohorts
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Cohort</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="isEditing ? updateCohort() : createCohort()">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Cohort Name</label>
                                        <input type="text" class="form-control"
                                               id="account-username" placeholder="Cohort Name"
                                               required=""
                                               v-model="cohort.name" >
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Programme</label>
                                        <select
                                            v-if="!isEditing"
                                            v-model="cohort.programme_id"
                                            id=""
                                            class="form-control"
                                            required
                                        >
                                            <option value="" selected>
                                                Select a programme
                                            </option>
                                            <option
                                                v-for="programme in programmes"
                                                :key="programme.id"
                                                :value="programme.id"
                                            >
                                                {{ programme.name }}
                                            </option>
                                        </select>
                                        <select
                                            v-if="isEditing"
                                            v-model="cohort.programme_id"
                                            id=""
                                            class="form-control"
                                            required
                                        >
                                            <option value="" selected>
                                                Select a programme
                                            </option>
                                            <option
                                                :value="cohort.programme_id"
                                            >
                                                {{ cohort.programme.name }}
                                            </option>
                                        </select>
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Cohort Capacity</label>
                                        <input type="number" class="form-control"
                                               id="account-username" placeholder="capacity"
                                               required=""
                                               v-model="cohort.capacity">
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Number of students per group</label>
                                        <input type="number" class="form-control"
                                               required=""
                                               v-model="cohort.no_per_group">
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Cohort Start date</label>
                                        <input type="date" class="form-control"
                                               required=""
                                               v-model="cohort.reg_start_date">
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Cohort end date</label>
                                        <input type="date" class="form-control"
                                               required=""
                                               v-model="cohort.reg_end_date">
                                        <div class="help-block"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 col-xs-12">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { SharedMethodMixin } from '../mixins/SharedMethodMixin.js';
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Create Cohort'},
    name: 'CohortCreate',
    props: ['isEditing', 'cohortData', 'programmes'],
    components: {FilePond, ValidationErrors, VueEditor},
    layout: Layout,
    mixins: [SharedMethodMixin],
    data() {
        return {
            cohort: {},
            saving: false,
            cohortImage: [],
        }
    },
    methods: {
        createCohort() {
            this.makePostAndPutRequest(route('admin.cohorts.store'));
        },

        updateCohort() {
            this.makePostAndPutRequest(route('admin.cohorts.update', this.cohort.id));
        },

        makePostAndPutRequest(route) {
            try {
                this.saving = true;
                const formData = new FormData();
                Object.keys(this.cohort).forEach(cohort => {
                    if (this.cohort[cohort]) {
                        formData.append(cohort, this.cohort[cohort]);
                    }
                });

                if (this.$refs.cohortImage && this.$refs.cohortImage.getFile()) {
                    formData.append('image', this.$refs.cohortImage.getFile().file);
                }

                let vm = this;

                this.$inertia.post(route, formData, {
                    preserveScroll: true,
                    onError: function (e) {
                        console.error(e);
                        vm.saving = false
                    },
                    onSuccess: function () {
                        vm.saving = false
                    },
                });

            } catch (e) {
                console.log(e);
                this.saving = false;
            }
        },

        formatDate(dateString) {
            const date = new Date(dateString);
            const splitDate = dateString.split(" ");
            const formattedDate = splitDate[0];
            return formattedDate;
        },
    },
    created() {

    },
    mounted() {
        if (this.isEditing) {
            this.cohort = this.cohortData;
            this.cohort.reg_start_date = this.formatDate(this.cohort.reg_start_date);
            this.cohort.reg_end_date = this.formatDate(this.cohort.reg_end_date);
            
        }
    }
}
</script>

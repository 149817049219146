<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Tutors</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item"><a :href="route('admin.tutors.index')">Tutors</a>
                            </li>
                            <li class="breadcrumb-item active">Details
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <div class="media mb-2">
                        <a class="mr-1" href="#">
                            <img :src="tutorData.profile_url" :alt="tutorData.first_name" class="users-avatar-shadow rounded-circle" height="100" width="100">
                        </a>
                        <div class="media-body pt-25">
                            <h4 class="media-heading">
                                <span class="users-view-name">{{ tutorData.first_name +' '+tutorData.last_name }}</span>
                            </h4>

                            <span>ID:</span>
                            <span class="users-view-id">305</span>
                        </div>
                    </div>
                </div>
            </section>

            <!-- <section class="users-view card">
                <div class="col-12 col-md-4 card-body">
                    <h5 class="mb-1"><i class="feather icon-user"></i> Personal Info</h5>
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Phone:</td>
                                <td class="users-view-username">{{ tutorData.phone }}</td>
                            </tr>
                            <tr>
                                <td>E-mail:</td>
                                <td class="users-view-email">{{ tutorData.email }}</td>
                            </tr>

                        </tbody>
                    </table>
                    <h5 class="mb-1"><i class="feather icon-link"></i> Social Links</h5>
                    <table class="table table-borderless">
                        <tbody>
                            <tr v-for="social in JSON.parse(tutorData.social_links)" :key="social.">
                                <td>Twitter:</td>
                                <td><a href="#">https://www.twitter.com/</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <h5 class="mb-1"><i class="feather icon-info"></i> Personal Info</h5>
                    <table class="table table-borderless mb-0">
                        <tbody>
                        <tr>
                            <td>Birthday:</td>
                            <td>03/04/1990</td>
                        </tr>
                        <tr>
                            <td>Country:</td>
                            <td>USA</td>
                        </tr>
                        <tr>
                            <td>Languages:</td>
                            <td>English</td>
                        </tr>
                        <tr>
                            <td>Contact:</td>
                            <td>+(305) 254 24668</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section> -->
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Tutor'},
    props: ['tutorData','lessons'],
    name: "TutorDetails",
    layout: Layout,

    data: () => ({

    }),
    created() {
        // this.allTutors = this.tutors.data
    },
    methods: {

    }
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Quizzes</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Edit Quiz {{quizData.name}} , Module: {{ quizData.module_title }}                           </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.quizzes.index')"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-eye icon-left"></i>
                         All Quizzes
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-header">
                    <h4 class="card-title">{{ isEditing ? 'Edit' : 'Add' }} Quiz</h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="updateQuiz()">

                        <h2>Edit Quiz - Assessment</h2>
                        <div class="form-group">
                            <label for="quizTitle">Quiz Title:{{ quizData.name }}</label>
                            <input id="quizTitle" type="text" v-model="quizData.name" class="form-control" required>
                        </div>
                        <!-- <div class="form-group">
                            <label for="course">Course:</label>
                            <div class="controls">
                                <select v-model="courseId" @change="fetchCourseModules($event)"  id="customSelect" class="custom-select block">
                                    <option value="" disabled selected>Select Course</option>
                                    <option v-for="(data, key) in courses" :value="data.id">{{data.title}}</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="form-group">
                            <label for="quizModule">Quiz Module:{{ quizData.module_title }}</label>
                                    <div class="controls">
                                        <select v-model="quizData.module_id"  id="customSelect" class="custom-select block">
                                            <option value="" selected>Select Quiz Module</option>
                                            <option v-for="(data, key) in modulesData" :value="data.id">{{data.title}} - Course:{{ data.course }}</option>
                                        </select>
                                    </div>
                        </div>
                        <div class="form-group">
                            <label for="quizTimeAllowed">Time Allowed  (in minutes) - Optional</label>
                            <input id="quizTimeAllowed" v-model="quizData.time_allowed" type="number" min="0" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="quizDescription">Quiz Description:</label>
                            <textarea id="quizDescription" class="form-control" v-model="quizData.description"></textarea>
                        </div>
                        <hr>
                        <h2>Questions</h2>

                        <div v-for="(question, index) in questions" :key="index" class="mb-4">
                            <h3>Question {{ index + 1 }}</h3>
                            <div class="form-group">
                                <label for="questionText">Question Text:</label>
                                <textarea required id="questionText" class="form-control" v-model="question.text"></textarea>
                            </div>
                            <div v-if="question.type === 'multiple-choice'">
                                <div class="form-group" v-for="(option, optionIndex) in question.options" :key="optionIndex">
                                    <div class="form-inline">
                                        <input type="checkbox" class="form-check-input mr-2" v-model="question.correctAnswers" :value="optionIndex">
                                        <input type="text" class="form-control mr-2" v-model="question.options[optionIndex]" required>
                                        <button type="button" class="btn btn-danger btn-sm" @click="removeOption(index, optionIndex)">Remove Option</button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" @click="addOption(index)">Add Option</button>
                                </div>
                            </div>
                            <!-- <div v-else>
                                <div class="form-group">
                                    <label for="answerText">Answer:</label>
                                    <input id="answerText" required type="text" class="form-control" v-model="question.answer">
                                </div>
                            </div> -->
                            <!-- <div class="form-group">
                                <label for="questionPoints">Points:</label>
                                <input id="questionPoints" type="number" required class="form-control" v-model.number="question.points" min="1" value="0">
                            </div> -->
                            <button type="button" class="btn btn-danger" @click="removeQuestion(index)">Remove Question</button>
                        </div>

                        <hr>
                        <br> <br>

                        <div class="form-group">
                            <button type="button" class="btn btn-primary" @click="addQuestion('multiple-choice')">Add
                                Multiple-Choice Question
                            </button>
                            <!-- <button type="button" class="btn btn-primary" @click="addQuestion('text-based')">Add
                                Text-Based Question
                            </button> -->
                        </div>

                        <hr>


                        <button type="submit" class="btn btn-primary">Update Quiz</button>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: {title: 'Edit Quiz'},
    name: 'QuizCreate',
    props: ['isEditing', 'courses','quiz','modules'],
    components: {FilePond, ValidationErrors, VueEditor, Link},
    layout: Layout,
    data() {
        return {
            saving: false,
            modulesData: {},
            quizData: {},
            quizTimeAllowed: '',
            courseId:null,
            questions: []
        }
    },
    methods: {
        addOption(index) {
            this.questions[index].options.push(`Option ${this.questions[index].options.length + 1}`);
        },
        addQuestion(type) {
            const question = {
                type: 'multiple-choice',
                text: '',
                options: [],
                correctAnswers: [],
                answer: '',
                points: 0
            };
            this.questions.push(question);
        },
        removeQuestion(index) {
            const questionPoints = this.questions[index].points;
            this.questions.splice(index, 1);
            this.totalPoints -= questionPoints;
        },
        removeOption(questionIndex, optionIndex) {
            const question = this.questions[questionIndex];
            const optionPoints = question.points / question.options.length;
            question.options.splice(optionIndex, 1);
            question.correctAnswers = question.correctAnswers.filter((index) => index !== optionIndex);
            question.points -= optionPoints;
        },
        updateQuiz() {
            const quizData = {
                id: this.quizData.id,
                module_id: this.quizData.module_id,
                time_allowed: this.quizData.time_allowed,
                name: this.quizData.name,
                description: this.quizData.description,
                questions: this.questions
            }
            // console.log(quizData)
            this.$inertia.post(route('admin.quizzes.update',quizData));
            // not the best approach  - this is quick fix approach

        },

        async fetchCourseModules(){
            let {data:{data:data}} = await axios.get(route('admin.courses.modules.index', this.courseId))

            this.modulesData = data.modules
        }

    },
    created() {
        this.modulesData = this.modules
        this.quizData = this.quiz[0];
        const result = this.quizData.questions.map((each)=>{
            // console.log(each)
            let optionsArray = [];
            let correctOptions = [];
            let optionsCount = 0;
            each.question_options.map((eachoption) => {

                optionsArray.push(eachoption.option_text);
                if(eachoption.is_correct == 1) {
                    correctOptions.push(optionsCount)
                }
                optionsCount++
            })
            const questionInitialData = {
                type: 'multiple-choice',
                text: each.question,
                options: optionsArray,
                correctAnswers: correctOptions,
                answer: '',
                points: 0,
            };
            this.questions.push(questionInitialData);
        });

        // console.log()
        // console.log(Array.isArray(this.quizData.questions))
        // for (const key in this.quizData.questions) {
            //  console.log(`${key}: ${this.quizData.questions.[key]}`);
        // }

    },
}
</script>

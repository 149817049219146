<template>
    <div>
        <div v-if="$page.props.flash.success && show" class="success notification" :class="{show: show}">
            {{ $page.props.flash.success }}
        </div>
        <div v-if="$page.props.flash.error && show" class="danger notification" :class="{show: show}">
            <div v-if="$page.props.flash.error" class="py-1 text-white text-sm font-medium">
                {{ $page.props.flash.error }}
            </div>
            <!--            <div v-else class="py-4 text-white text-sm font-medium">-->
            <!--                <span v-if="Object.keys($page.props.errors).length === 1">There is one form error.</span>-->
            <!--                <span v-else>There are {{ Object.keys($page.props.errors).length }} form input errors.</span>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "FlashMessages",
    data: () => {
        return {
            show: false
        }
    },
    watch: {
        show() {
            setTimeout(() => {
                this.show = this.show ? !this.show : this.show
            }, 4000)
        },
        '$page.props.flash': {
            handler() {
                this.show = true
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
.notification {
    position: fixed;
    display: flex;
    top: 50px;
    right: -40px;
    z-index: 1090;
    opacity: 0;

    box-shadow: 2px 4px 8px rgba(0, 0, 0, .5);
    padding: 10px 30px 10px 60px;
    transition: .3s all;

    color: white;
}

.notification::before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: 30px;
    top: 50%;
    background-size: cover;
    transform: translateY(-50%);
}

.success {
    background-color: green;
}

.success::before {
    background-image: url("/images/tick.svg");
}


.danger {
    background-color: #D12B19;
}

.danger::before {
    background-image: url("/images/alert-white.svg");
}


.show {
    display: block;
    right: 20px;
    opacity: 1;
}

span {
    cursor: pointer;
    color: white;
}


</style>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Quiz Answers</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Answers

                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4> Students</h4>
                </div>

                <div class="card-body">

                    <vue-good-table :columns="columns" :rows="allAnswers" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                        }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <span v-if="props.column.label === 'Course'">
                                {{ props.row.student.course?.title  == "" ? '-': props.row.student.course?.title }}
                            </span>
                            <span v-else-if="props.column.label === 'Programme'">
                                {{ props.row.student.course?.programme?.name == "" ? '-': props.row.student.course?.programme?.name }}
                            </span>
                            <span v-else-if="props.column.field === 'action'">
                                <span class="dropdown">
                                    <a id="btnSearchDrop2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        class="dropdown-toggle dropdown-menu-right"><i class="fa fa-ellipsis-v"></i></a>
                                    <span aria-labelledby="btnSearchDrop2" class="dropdown-menu mt-1 dropdown-menu-right"
                                        x-placement="bottom-end"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 19px, 0px);"
                                        x-out-of-boundaries="">
                                        <Link :href="route('admin.quizzes.getStudentQuizQuestionAndAnswers',[props.row.user_id,id])" class="dropdown-item edit"><i class="feather icon-edit-2"></i>
                                            View Submission:
                                        </Link>
                                    </span>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>



    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';



export default {
    metaInfo: {title: 'Quiz Answers'},
    props: ['answers','id'],
    components: {
        Link
    },
    name: "QuizAnswers",
    layout: Layout,

    data: () => ({
        search: null,
        pageType: null,
        filter: {},
        isLoadingFilter: false,
        allAnswers: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'Student', field: 'student.full_name'},
            {label: 'Email', field: 'student.email'},
            {label: 'Course', field: 'student.course.title'},
            {label: 'Programme', field: 'student.course.programme.name'},
            {label: 'Action', field: 'action'},
        ],
        tableDataLoading: false,
        saving: false,
        quizEdit: [],

    }),
    created() {
        console.log('this.answers.data', this.answers)
        this.allAnswers = this.answers
        this.totalRecords = this.answers.length
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.answers.paginate';

                let {data:{data: answers}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = answers.total;
                this.paginationOptions.perPage = answers.per_page;
                this.allAnswers = answers.data
            } catch (e) {
                console.log(e);
            }
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    },
}
</script>

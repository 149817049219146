<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Lessons</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Lessons
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <!-- <Link :href="route('admin.lessons.create',module_id)"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add lesson
                </Link> -->
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Lessons</h4>
                </div>

                <div class="card-body">
                    <vue-good-table :columns="columns" :rows="allLessons" :line-numbers="true" compactMode :search-options="{
                        enabled: true, skipDiacritics: true, initialSortBy: { field: 'created_at', type: 'desc' }
                        }" :pagination-options="{ enabled: true, mode: 'pages', position: 'bottom', perPage: 10
                    }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">

                            <span v-if="props.column.field === 'action'">
                                <!-- {{ props.row }} -->
                                <span class="dropdown">
                                    <a :href="route('admin.lessons.lesson.edit',[props.row.module_id,props.row.id])"  ><i title="Edit Lesson" class="feather icon-edit"></i></a>
                                    <a  href=""  @click.prevent="showAlert( props.row)"><i  title="Delete Lesson" class="feather icon-trash"></i></a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>

    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Lessons'},
    props: ['lessons'],
    components: {
        Link
    },
    name: "LessonIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allLessons: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // {label: 'S/N', field: 'sn'},
            {label: 'Title', field: 'title'},
            {label: 'Tutor', field: 'tutor'},
            {label: 'Position', field: 'position'},
            {label: 'Module', field: 'module_title'},
            {label: 'Date Added', field: 'created_at'},
            {label: 'Action', field: 'action'},
        ],
        tableDataLoading: false,
        saving: false,


    }),
    created() {
        this.allLessons = this.lessons
        this.totalRecords = this.lessons.length
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.all-lessons.paginate';

                let {data:{data: assignments}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = assignments.total;
                this.paginationOptions.perPage = assignments.per_page;
                this.allAssignments = assignments.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteLesson(lesson){
            this.$inertia.delete(route('admin.lessons.lesson.destroy', [lesson.module_id,lesson.id]),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },

        showAlert(lesson) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Lesson?",
                text: "All histories associated with this lesson will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteLesson(lesson)
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>

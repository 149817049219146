<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Quiz Questions</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Questions

                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4> {{this.quiz.name}}'s questions</h4>
                    <Link :href="route('admin.quizzes.edit', quiz.id)">Edit Quiz</Link>
                </div>
            </section>

            <div class="">
                <div class="row">
                    <div v-for="(question,index) in questions" :key="question.id" class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h3>{{index + 1}}. {{question.question}}</h3>
                            </div>
                            <div class="card-bod">
                                <div v-if="question.has_options" class="ml-2">
                                    <p v-for="option in question.question_options" :class="{'text-success':option.is_correct}">
                                        {{ option.option_text }}
                                    </p>
                                </div>
                                <p v-else>Text based</p>
                            </div>

                            <div class="card-footer d-flex justify-content-between">
                                <div>
                                    Score: {{question.score}}
                                </div>
                                <!-- <div>
                                    <Link href="" class="btn btn-warning">Edit</Link>
                                    <Link href="" class="btn btn-danger">Delete</Link>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';



export default {
    metaInfo: {title: 'Quiz Questions'},
    props: ['questions','quiz','id'],
    components: {
        Link
    },
    name: "QuizQuestions",
    layout: Layout,

    data: () => ({
        search: null,
        pageType: null,
        filter: {},
        isLoadingFilter: false,
        allQuestions: [],
        totalRecords: 0,
        sn:0,

    }),
    created() {
        console.log('this.questions.', this.questions)
        this.allQuestions = this.questions
        this.totalRecords = this.questions.length
    },
}
</script>

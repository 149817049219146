<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Assignments</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Assignments
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.assignments.create')"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add assignment
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Assignments</h4>
                </div>

                <div class="card-body">
                    <vue-good-table :columns="columns" :rows="allAssignments" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'created_at', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                    }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'action'">
                                    <!-- <span class="badge"
                                          :class="{ 'badge-success': props.row.is_complete,  'badge-danger': !props.row.is_complete}">{{
                                            props.row.is_complete ? 'Complete' : 'Incomplete'
                                        }}</span>
                                        <br>
                                    
                                    </span>
                            <span v-else-if="props.column.field === 'action'"> -->
                                <span style="display: flex; justify-items: center; align-items: center;" class="dropdown">
                                    <a :href="route('admin.assignments.edit', props.row.id)">
                                        <i title="Edit Assignment" class="feather icon-edit"></i>
                                    </a>
                                    <a class="dropdown-item text-danger delete"  href=""  @click.prevent="showAlert( props.row.id)">
                                        <i  title="Delete Assignment" class="feather icon-trash"></i>
                                    </a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>

  

    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Assignments'},
    props: ['isEditing','assignments', 'filters'],
    components: {
        Link
    },
    name: "AssignmentIndex",
    layout: Layout,

    data: () => ({
        search: null,
        pageType: null,
        filter: {},
        isLoadingFilter: false,
        allAssignments: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'Name', field: 'name'},
            {label: 'Description', field: 'description'},
            {label: 'Due Date', field: 'due_date'},
            {label: 'Cohort Name', field: 'cohort_title'},
            {label: 'Module Name', field: 'module_title'},
            {label: 'Group Name', field: 'group_name'},
            {label: 'Date', field: 'created_at'},
            {label: 'Action', field: 'action'},
        ],
        tableDataLoading: false,
        saving: false,
        assignmentEdit: [],

    }),
    created() {
        this.allAssignments = this.assignments.data
        this.totalRecords = this.assignments.total
    },
    methods: {

        addassignmentModal() {
            $('.add-assignment-modal').modal('show');
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.assignments.paginate';

                let {data:{data: assignments}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = assignments.total;
                this.paginationOptions.perPage = assignments.per_page;
                this.allAssignments = assignments.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteAssignment(assignmentId){
            this.$inertia.delete(route('admin.assignments.destroy', assignmentId),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },

        addassignment() {
            // alert()
            // let vm = this;
            // this.reloadData = Math.floor(Math.random() * 10) + 1;
            // this.$inertia.post(route('admin.assignments.modules.store', this.course.id), this.module, {
            //     preserveScroll: true,
            //     onError: function (e) {
            //         console.error(e);
            //         vm.saving = false
            //     },
            //     onSuccess: function () {
            //         vm.saving = false
            //         vm.moduleEdit = [];
            //         // vm.user_id = null;
            //         $('.add-module-modal').modal('hide')
            //         this.allModules = this.modules.data
            //         this.totalRecords = this.modules.total
            //     },
            // });
        },


        showAlert(assignmentId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Assignment?",
                text: "All histories associated with this assignment will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteAssignment(assignmentId)
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    },
    watch: {
        search: function (val) {
            this.allAssignments =  Inertia.get('/assignments', {
                search:val,
            });
            this.pageType = val;
            console.log(this.pageType);

        },
    }
}
</script>

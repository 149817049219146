export const SharedMethodMixin = {
    methods: {
        formatDateForInput(dateString) {
            const date = new Date(dateString);
            const splitDate = dateString.split(" ");
            const formattedDate = splitDate[0];
            return formattedDate;
        },
    },
  };

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Application Form</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                                <Link :href="route('admin.applications.index')">
                                    Applications
                                </Link>
                            </li>
                            <li class="breadcrumb-item active">
                                Application Form
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        {{ "Add" }} Application Form
                    </h4>
                </div>
                <div class="card-body">
                    <validation-errors></validation-errors>
                    <form @submit.prevent="createApplicationForm()">
                        <div class="form-group">
                            <div class="controls">
                                <label class="form-label">Programme</label>
                                <select v-model="programmeId" id="" class="form-control" required
                                    @change="getProgrammeCohort">
                                    <option value="" selected>
                                        Select a programme
                                    </option>
                                    <option v-for="programme in programmes" :key="programme.id" :value="programme.id">
                                        {{ programme.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="controls">
                                <label class="form-label">Cohort</label>
                                <select v-model="cohortId" id="" class="form-control" required>
                                    <option value="" selected>
                                        Select a cohort
                                    </option>
                                    <option v-for="cohort in this.cohorts" :key="cohort.id" :value="cohort.id">
                                        {{ cohort.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="applicationTitle">Application Name:</label>
                            <input id="applicationTitle" type="text" class="form-control" v-model="applicationTitle"
                                required />
                        </div>
                        <div class="form-group">
                            <label for="applicationDescription">Application Description:</label>
                            <textarea id="applicationDescription" class="form-control"
                                v-model="applicationDescription"></textarea>
                        </div>

                        <hr />

                        <div v-for="(question, index) in questions" :key="index" class="mb-4">
                            <h3>Question {{ index + 1 }}</h3>
                            <div class="form-group">
                                <label for="questionText">Question Text:</label>
                                <textarea id="questionText" class="form-control" v-model="question.text"></textarea>
                            </div>
                                <!-- Add checkbox for making questions compulsory -->
                            <div class="form-group">
                                <label for="isRequired">Is Required?</label>
                                <input type="checkbox" v-model="question.is_required" />
                            </div>
                            <!-- end checkbox -->
                            <div v-if="question.type === 'multiple-choice'">
                                <div class="form-group" v-for="(
                                        option, optionIndex
                                    ) in question.options" :key="optionIndex">
                                    <div class="form-inline">
                                        <!--   <input type="checkbox" class="form-check-input mr-2" v-model="question.correctAnswers" :value="optionIndex">-->
                                        <input type="text" class="form-control mr-2" v-model="question.options[optionIndex]
                                            " required />
                                        <button type="button" class="btn btn-danger btn-sm" @click="
                                            removeOption(index, optionIndex)
                                            ">
                                            X
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" @click="addOption(index)">
                                        Add Option
                                    </button>
                                </div>
                            </div>
                            <!--   <div class="form-group">-->
                            <!--   <label for="questionPoints">Points:</label>-->
                            <!--   <input id="questionPoints" type="number" class="form-control" v-model.number="question.points" min="1" value="0">-->
                            <!--   </div>-->
                            <button type="button" class="btn btn-danger" @click="removeQuestion(index)">
                                Remove Question
                            </button>
                        </div>

                        <hr />
                        <br />
                        <br />

                        <div class="form-group">
                            <button type="button" class="btn btn-secondary" @click="addQuestion('multiple-choice')">
                                Add Multiple-Choice Question
                            </button>
                            <button type="button" class="btn btn-secondary" @click="addQuestion('text-based')">
                                Add Text-Based Question
                            </button>
                        </div>

                        <hr />

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Application Start date</label>
                                        <input type="date" class="form-control" required v-model="start_date" />
                                        <div class="help-block"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label for="account-username">Application End date</label>
                                        <input type="date" class="form-control" required v-model="end_date" />
                                        <div class="help-block"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <button type="submit" class="btn btn-primary pull-right">Save</button> -->
                        <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="saving">
                            {{ "Save" }}
                            <i class="fa fa-spinner spinner" v-if="saving"></i>
                        </button>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import vueFilePond from "vue-filepond";
import ValidationErrors from "@/Shared/ValidationErrors";
import { VueEditor } from "vue2-editor";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

export default {
    metaInfo: { title: "Create Application Form" },
    name: "ApplicationCreate",
    props: ["applicationFormData", "programmes"],
    components: { FilePond, ValidationErrors, VueEditor,  Link },
    layout: Layout,
    data() {
        return {
            application: {},
            cohorts: {},
            saving: false,
            applicationTitle: "",
            programmeId: null,
            cohortId: null,
            programme: {},
            applicationQuestions: {},
            applicationDescription: "",
            start_date: null,
            end_date: null,
            questions: [
                {
                    type: "multiple-choice",
                    text: "",
                    options: ["", "", ""],
                    correctAnswers: [],
                    points: 0,
                    // isRequired: false, 
                },
                { type: "text-based", text: "", answer: "", points: 0 },
            ],
        };
    },
    methods: {
        addQuestion(type) {
            const question = {
                type,
                text: "",
                options: ["Option 1", "Option 2"],
                correctAnswers: [],
                answer: "",
                points: 0,
            };
            this.questions.push(question);
        },
        removeQuestion(index) {
            const questionPoints = this.questions[index].points;
            this.questions.splice(index, 1);
            this.totalPoints -= questionPoints;
        },
        removeOption(questionIndex, optionIndex) {
            const question = this.questions[questionIndex];
            const optionPoints = question.points / question.options.length;
            question.options.splice(optionIndex, 1);
            question.correctAnswers = question.correctAnswers.filter(
                (index) => index !== optionIndex
            );
            question.points -= optionPoints;
        },
        addQuiz() {
            const quizData = {
                title: this.applicationTitle,
                description: this.applicationDescription,
                questions: this.questions,
                start_date: this.start_date,
                end_date: this.end_date,
            };

            // code to add quiz to database goes here
            // after successful addition, clear the inputs
            this.applicationTitle = "";
            this.applicationDescription = "";
            this.start_date = "";
            this.end_date = "";
            this.questions = [
                {
                    text: "",
                    options: ["option 1", "option 2"],
                    correctAnswers: [],
                    points: 1,
                },
            ];
        },
        addOption(index) {
            this.questions[index].options.push(
                `Option ${this.questions[index].options.length + 1}`
            );
        },
        createApplicationForm() {
            let vm = this;

            const applicationData = {
                name: this.applicationTitle,
                description: this.applicationDescription,
                questions: this.questions,
                programme_id: this.programmeId,
                cohort_id: this.cohortId,
                start_date: this.start_date,
                end_date: this.end_date,
            };
            this.$inertia.post(
                route("admin.applications.store"),
                applicationData,
                {
                    preserveScroll: true,
                    onError: function (e) {
                        console.error(e);
                        vm.saving = false;
                    },
                    onSuccess: function () {
                        vm.saving = false;
                    },
                }
            );
        },

        makePostAndPutRequest(route) {
            try {
                this.saving = true;
                const formData = new FormData();
                Object.keys(this.application).forEach((application) => {
                    if (this.application[application]) {
                        formData.append(
                            application,
                            this.application[application]
                        );
                    }
                });

                let vm = this;

                this.$inertia.post(route, formData, {
                    preserveScroll: true,
                    onError: function (e) {
                        console.error(e);
                        vm.saving = false;
                    },
                    onSuccess: function () {
                        vm.saving = false;
                    },
                });
            } catch (e) {
                console.log(e);
                this.saving = false;
            }
        },

        formatDate(dateString) {
            const date = new Date(dateString);
            const splitDate = dateString.split(" ");
            const formattedDate = splitDate[0];
            return formattedDate;
        },

        async getProgrammeCohort() {
            let data = await axios.get(route('admin.programmes.getProgrammeCohortsForSelect', this.programmeId))
            console.log('data', data);

            this.cohorts = data.data
        }
    },
    created() {
        // console.log("applicationFormData", this.applicationFormData);
    },
};
</script>
<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Assessments</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Assessments

                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.preliminaryAssessments.create')"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add Assessment
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4> Assessments</h4>
                </div>

                <div class="card-body">
                    <vue-good-table :columns="columns" :rows="allAssessments" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'dateAdded', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                        }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <!-- <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span> -->
                             <span v-if="props.column.field === 'action'">
                                <span class="dropdown">
                                    <a  :href="route('admin.preliminaryAssessments.submissions',props.row.id)"><i class="feather icon-eye" title="View Assessment Answers"></i></a>
                                    <a :href="route('admin.preliminaryAssessments.edit', props.row.id)"><i title="Edit Assessment" class="feather icon-edit"></i></a>
                                    <a class="text-danger delete"  href=""  @click.prevent="showDeleteAlert( props.row.id)"><i  title="Delete Assessment" class="feather icon-trash"></i></a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>



    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';



export default {
    metaInfo: {title: 'Preliminary Assessments'},
    props: ['assessments','filters'],
    components: {
        Link
    },
    name: "AssessmentIndex",
    layout: Layout,

    data: () => ({
        search: null,
        pageType: null,
        filter: {},
        isLoadingFilter: false,
        allAssessments: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // {label: 'S/N', field: 'sn'},
            {label: 'Assessment Title', field: 'name'},
            {label: 'Course Name', field: 'course.title'},
            {label: 'Description', field: 'quiz.description'},
            {label: 'Date', field: 'created_date'},
            // {label: 'Status', field: 'status'},
            {label: 'Action', field: 'action'},
        ],
        tableDataLoading: false,
        saving: false,
        assessmentEdit: [],

    }),
    created() {
        this.allAssessments = this.assessments
        this.totalRecords = this.assessments.length
    },
    methods: {

        addAssessmentModal() {
            $('.add-assessment-modal').modal('show');
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.preliminaryAssessments.paginate';

                let {data:{data: assessments}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = assessments.total;
                this.paginationOptions.perPage = assessments.per_page;
                this.allAssessments = assessments.data
            } catch (e) {
                console.log(e);
            }
        },

        showDeleteAlert(assessmentId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this assessment?",
                text: "All histories associated with this assessment will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteAssessment(assessmentId)
                }
            });
        },


        deleteAssessment(assessmentId){
            this.$inertia.delete(route('admin.preliminaryAssessments.destroy', assessmentId),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },


        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    },
    watch: {
        search: function (val) {
            this.allAssessments =  Inertia.get('/assessments', {
                search:val,
                // preserveState : true,
                // replace       : true,
                // preserveScroll: true,
            });
            this.pageType = val;
            console.log(this.pageType);

        },
    }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('h3',{staticClass:"content-header-title mb-0"},[_vm._v("Attendance Submissions")]),_vm._v(" "),_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"breadcrumb-wrapper col-12"},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_vm._v(" "),_c('li',{staticClass:"breadcrumb-item"},[_c('Link',{attrs:{"href":_vm.route('admin.attendances.index')}},[_vm._v("Attendances")])],1),_vm._v(" "),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Attendance Submissions\n                        ")])])])])])]),_vm._v(" "),_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"card"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.attendanceData,"line-numbers":true,"compactMode":"","search-options":{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: { field: 'dateAdded', type: 'desc' }
                    },"pagination-options":{
                    enabled: true,
                    mode: 'pages',
                    position: 'bottom',
                    perPage: 10
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return undefined}}])},[_c('div',{staticClass:"loading-container",attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('img',{attrs:{"src":"/images/Spinner.svg","alt":""}})])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Students in this Attendance:")])])
}]

export { render, staticRenderFns }
<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Admins</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Admins
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="users-view">
                <!-- users view media object start -->
                <div class="row">
                    <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                                <img src="/logo.png"
                                     alt="users view avatar" class="users-avatar-shadow rounded-circle" height="64"
                                     width="64">
                            </a>
                            <div class="media-body pt-25">
                                <h4 class="media-heading"><span class="users-view-name">{{
                                        admin.first_name + ' ' + admin.last_name
                                    }}</span></h4>
                                <span>ID:</span>
                                <span class="users-view-id">{{ admin.id }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
                        <a href="#" class="btn btn-sm mr-25 border">Profile</a>
                        <a :href="route('admin.admins.edit', admin.id)"
                           class="btn btn-sm btn-primary">Edit</a>
                    </div>
                </div>
                <!-- users view media object ends -->
                <!-- users view card data start -->
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Registered:</td>
                                            <td>{{ admin.created_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Latest Activity:</td>
                                            <td class="users-view-latest-activity">{{ admin.updated_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Role:</td>
                                            <td class="users-view-role">Admin</td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td><span class="badge badge-success users-view-status">Active</span></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- users view card data ends -->
                <!-- users view card details start -->
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">
                            <div class="col-12">
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Name:</td>
                                        <td class="users-view-name"> {{
                                                admin.first_name + ' ' + admin.last_name
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td class="users-view-email">{{ admin.email }}</td>
                                    </tr>

                                    </tbody>
                                </table>
                                <h5 class="mb-1"><i class="feather icon-info"></i> Personal Info</h5>
                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Contact:</td>
                                        <td class="users-view-email">0809823423423</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- users view card details ends -->

            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'

export default {
    metaInfo: {title: 'Create Admin'},
    name: 'DriverShow',
    props: ['admin'],
    layout: Layout,
    data: () => ({
        saving: false
    }),
    methods: {},
    mounted() {

    },
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">students</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">students {{ student.country }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <form novalidate @submit.prevent="savestudent">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>First Name</label>
                                        <input type="text" v-model="student.first_name" class="form-control"
                                            placeholder="First name" required>
                                        <div v-if="student.errors.first_name" class="text-danger text-small">
                                            {{ student.errors.first_name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                 
                                    <div class="controls">
                                        <label>Last Name</label>
                                        <input type="text" v-model="student.last_name" class="form-control"
                                            placeholder="First name" required>
                                        <div v-if="student.errors.last_name" class="text-danger text-small">
                                            {{ student.errors.last_name }}
                                        </div>
                                    
                                </div>
                                </div>

                            </div>

                            <div class="col-12 col-sm-12">
                               

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Phone</label>
                                        <input type="text" v-model="student.phone" class="form-control"
                                            placeholder="phone" required>
                                        <div v-if="student.errors.phone" class="text-danger text-small">
                                            {{ student.errors.phone }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Email</label>
                                        <input type="text" v-model="student.email" class="form-control"
                                            placeholder="email" required>
                                        <div v-if="student.errors.email" class="text-danger text-small">
                                            {{ student.errors.email }}
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="col-12 col-sm-12">
                               

                               <div class="form-group">
                                   <div class="controls">
                                       <label>Gender</label>
                                       <div class="controls">
                                           <select required v-model="student.gender"  id="customSelect" class="custom-select block">
                                               <option value="" disabled selected="">Select country</option>
                                               <option value="male">Male</option>
                                               <option value="female">Female</option>
                                           </select>
                                           <div v-if="student.errors.gender" class="text-danger text-small">
                                            {{ student.errors.gender }}
                                        </div>
                                       </div>
                                   </div>
                               </div>

                           </div>

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                    :disabled="saving">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'

export default {
    metaInfo: { title: 'Create/Edit student' },
    name: 'studentCreate',
    props: ['isEditing', 'studentData'],
    layout: Layout,
    data() {
        return {
            student: this.$inertia.form({
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                role: null,
                gender: null,
            }),
            saving: false
        }
    },
    methods: {
        async savestudent() {
            let vInstance = this;
            vInstance.saving = true;

            try {
                if (this.isEditing) {
                    console.log({...this.student})
                    this.student.post(route('admin.students.update', this.studentData.id), this.student, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                    });
                } else {
                    this.student.post(route('student.students.store'), this.student, {
                        preserveScroll: true,
                        onSuccess: () => vInstance.saving = false,
                    });

                    vInstance.saving = false;
                }
            } catch (e) {
                console.log(e);
                vInstance.saving = false
            }
        }
    },
    mounted() {
        if (this.isEditing) {
            this.student.first_name = this.studentData.first_name
            this.student.last_name = this.studentData.last_name
            this.student.email = this.studentData.email
            this.student.phone = this.studentData.phone
            this.student.role = this.studentData.role
            this.student.gender = this.studentData.gender
        }
    },
}
</script>

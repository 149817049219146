<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Application Forms</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Application Form
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <Link :href="route('admin.applications.create')"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add Application
                </Link>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Application Forms</h4>
                </div>

                <div class="card-body">
                     <vue-good-table :columns="columns" :rows="allApplicationForms" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'dateAdded', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'bottom',
                        perPage: 10
                        }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <!-- <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span> -->
                             <span v-if="props.column.label === 'Application Date'">
                                Start Date: <b>{{ formatDate(props.row.start_date) }}</b><br>
                                End Date: <b>{{ formatDate(props.row.end_date) }}</b><br>
                            </span>
                            <span v-else-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>

                            <span v-else-if="props.column.field === 'action'">

                                <span style="display: flex; justify-items: center; align-items: center;" class="dropdown">
                                    <a  :href="route('admin.applications.submittedApplications',props.row.id)"><i class="feather icon-eye" title="View Submitted Applications"></i></a>
                                    <!-- <a  :href="route('admin.applications.edit',props.row.id)"><i class="feather icon-edit" title="Edit Application"></i></a> -->
                                    <a  :href="route('admin.applications.applicationQuestions',props.row.id)"><i class="feather icon-file" title="View Application Form Questions"></i></a>
                                    <a   href=""  @click.prevent="showAlert( props.row.id)"><i  title="Delete Application" class="feather icon-trash"></i></a>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import moment from 'moment';
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Application Form'},
    props: ['applicationForms'],
    components: {
        Link
    },
    name: "ApplicationFormIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allApplicationForms: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // {label: 'S/N', field: 'sn'},
            {label: 'Application Name', field: 'name'},
            {label: 'Programme', field: 'programme.name'},
            {label: 'Cohort', field: 'cohort.name'},
            {label: 'Application Date', field: 'dateAdded'},
            {label: 'Date Added', field: 'dateAdded'},
            // {label: 'Status', field: 'status'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    created() {
        this.allApplicationForms = this.applicationForms
        this.totalRecords = this.applicationForms.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.applications.paginate';

                let {data:{data: applicationForms}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = applicationForms.total;
                this.paginationOptions.perPage = applicationForms.per_page;
                this.allApplicationForms = applicationForms.data
            } catch (e) {
                console.log(e);
            }
        },

        deleteApplicationForm(applicationFormId){
            this.$inertia.delete(route('admin.applications.destroy', applicationFormId),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },


        showAlert(applicationFormId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Application Form?",
                text: "All histories associated with this Application Form will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteApplicationForm(applicationFormId)
                }
            });
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            } else {
                return '-';
            }
        },
    }
}
</script>

<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Roles</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Roles
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
                <button @click.prevent="addRoleModal" data-toggle="modal" data-target="#faq-modal"
                        type="button"
                        class="btn btn-outline-primary float-md-right"><i class="feather icon-plus icon-left"></i>
                    Add Role
                </button>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Manage Roles</h4>
                </div>

                <div class="card-body">
                    <div class="search-container">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-6 d-flex align-items-center">
                                <img src="/images/search.svg" alt="">

                            </div>
                        </div>

                    </div>

                    <vue-good-table
                        class="dataTable"
                        mode="remote"
                        :isLoading.sync="tableDataLoading"
                        :totalRows="totalRecords"
                        :pagination-options="paginationOptions"
                        @on-page-change="onPageChange"
                        @on-per-page-change="onPerPageChange"
                        @on-sort-change="onSortChange"
                        styleClass="vgt-table"
                        :columns="columns"
                        :rows="allRoles"
                    >

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                             <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span>
                            <span v-else-if="props.column.field === 'action'">
                                <!-- <a :href="`/admin/admins/${props.row.id}/edit`">
                                    <i class="feather icon-edit"></i> edit
                                </a> -->
                                <a @click.prevent="editRoleModal(props.row)" data-toggle="modal" data-target="#faq-modal"
                                type="button" :href="route('admin.roles.edit',props.row.id)">
                                    Edit <i class="feather icon-edit"></i>
                                </a> 
                                
                                | <a :href="route('admin.roles.permissions.edit', props.row.id)"
                                          class="text-secondary">
                                    <i class="feather icon-edit"></i> Manage permissions
                                </a> |
                                <a href="#" @click="showDeleteAlert(props.row.id)"
                                    class="text-danger">
                                    <i class="feather icon-trash-2"></i> delete
                                </a>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>

        <div class="modal fade add-role-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="addRole">
                        <div class="modal-header"><h4 id="myModalLabel1" class="modal-title">Add Role</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group"><label>Role Name</label>
                                <input type="text" required="required"
                                       class="form-control" v-model="role.name">
                                <div v-if="role.errors.name" class="text-danger text-small">
                                    {{ role.errors.name }}
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Save <i class="fa fa-spinner fa-spin" v-if="saving"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade edit-role-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="editRole">
                        <div class="modal-header"><h4 id="myModalLabel1" class="modal-title">Edit Role</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="text-danger text-small">
                                   {{ editErrorMsgId }}
                            </div>
                            <div class="text-danger text-small">
                                   {{ editErrorMsgName }}
                            </div>
                            <br>
                            <div class="form-group"><label>Role Name</label>
                                <input type="text" required="required"
                                       class="form-control" v-model="role.name">
                                

                                <input type="hidden" required="required"
                                       class="form-control" v-model="role.id">
                                <div v-if="role.errors.id" class="text-danger text-small">
                                    {{ role.errors.id }}
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button class="btn btn-primary" :disabled="saving">
                                Update <i class="fa fa-spinner fa-spin" v-if="saving"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Roles'},
    name: 'RoleIndex',
    props: ['roles'],
    layout: Layout,

    data() {
        return {
            filter: {},
            isLoadingFilter: false,
            allRoles: [],
            editErrorMsgId: null,
            editErrorMsgName: null,
            role: this.$inertia.form({
                name: null
            }),
            totalRecords: 0,
            serverParams: {
                page: 1,
                perPage: 10
            },
            paginationOptions: {
                enabled: true,
                mode: 'pages',
                perPage: 10,
                perPageDropdown: [5, 10, 20, 50, 100, 200],
                dropdownAllowAll: false,
            },
            columns: [
                {label: 'S/N', field: 'sn'},
                {label: 'Name', field: 'name'},
                {label: 'Action', field: 'action', sortable: false},
            ],
            tableDataLoading: false,
            saving: false,
        }
    },
    mounted() {
        this.allRoles = this.roles.data;
        this.totalRecords = this.roles.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            // todo: make this work , change the route and response data
            try {
                const type = this.type;
                let routeName = 'cms.blogs.paginate';
                if (type === 'research') {
                    routeName = 'cms.research-insight.paginate';
                }

                let {data: {data: {posts}}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = posts.total;
                this.paginationOptions.perPage = posts.per_page;
                this.post = posts.data
            } catch (e) {
                console.log(e);
            }
        },

        async editRole() {
            
            const roleData = {
                id: this.role.id,
                name: this.role.name,
            };

            this.saving = true;
            this.$inertia.post(route('admin.roles.update', roleData),roleData, {
                preserveScroll: true,
                onSuccess: () => {
                    this.allRoles = this.roles.data;
                    $('.edit-role-modal').modal('hide');
                    this.saving = false;
                },
                onError: (e) => {
                    this.saving = false;
                    console.log(e);
                    this.editErrorMsgId = e.id;
                    this.editErrorMsgName = e.name;
                }
            })
        },

        async addRole() {
            this.saving = true;
            this.role.post(route('admin.roles.store'), {
                preserveScroll: true,
                onSuccess: () => {
                    this.allRoles = this.roles.data;
                    $('.add-role-modal').modal('hide');
                    this.saving = false;
                },
                onError: () => {
                    this.saving = false;
                }
            })
        },

        async editRole() {
            
            const roleData = {
                id: this.role.id,
                name: this.role.name,
            };
            // console.log(roleData)

            this.saving = true;
            this.$inertia.post(route('admin.roles.update', roleData),roleData, {
                preserveScroll: true,
                onSuccess: () => {
                    this.allRoles = this.roles.data;
                    $('.edit-role-modal').modal('hide');
                    this.saving = false;
                },
                onError: (e) => {
                    this.saving = false;
                    console.log(e);
                    this.editErrorMsgId = e.id;
                    this.editErrorMsgName = e.name;
                }
            })
        },

        showDeleteAlert(id) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this role with all its associated histories ?",
                // text: "All histories associated with this course will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, update it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteRole(id)
                }
            });
        },

        async deleteRole(id) {
            // todo: make this work , change the route
            this.$inertia.delete(route('admin.roles.delete', id), {
                preserveScroll: true,
                onSuccess: () => {
                    location.reload(2);
                }
            })
        },

        addRoleModal() {
            $('.add-role-modal').modal('show');
        },

        editRoleModal(row) {
            this.role.name = row.name;
            this.role.id = row.id;
            $('.edit-role-modal').modal('show');
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
        // showAlert(roleId) {
        //     let vInstance = this;

        //     this.$swal.fire({
        //         title: "Are you sure you want to delete this roles?",
        //         text: "You won't be able to revert this!",
        //         type: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#3085d6",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //         confirmButtonClass: "btn btn-primary mr-3",
        //         cancelButtonClass: "btn btn-danger ml-1",
        //         buttonsStyling: false
        //     }).then(function (result) {
        //         if (result.value) {
        //             vInstance.deleteRole(roleId)
        //         }
        //     });
        // },
    }
}
</script>

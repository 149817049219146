<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Mentors</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Mentor
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>All Mentors {{ totalRecords }}</h4>
                </div>

                <div class="card-body">
                    <vue-good-table :columns="columns" :rows="allMentors" :line-numbers="true" compactMode :search-options="{
                                                                enabled: true,
                                                                skipDiacritics: true,
                                                                initialSortBy: { field: 'dataJoined', type: 'desc' }
                                                                }" :pagination-options="{
                                                                                            enabled: true,
                                                                                            mode: 'pages',
                                                                                            position: 'bottom',
                                                                                            perPage: 10
                                                                                            }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">
                            <!-- <span v-if="props.column.field === 'sn'">
                                    {{ serialNo(props) }}
                             </span> -->
                            <span v-if="props.column.field === 'email'">
                                <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
                            </span>
                            <span v-else-if="props.column.label === 'Activation Status'">
                                <span class="badge"
                                    :class="{ 'badge-success': props.row.status == 1,  'badge-danger': props.row.status == 0 }">
                                    {{ props.row.status == 1 ? "Active":"Deactivated" }}
                                </span>
                            </span>
                            <span v-else-if="props.column.field === 'action'">


                                <span class="dropdown">
                                <a href="" :href="route('admin.mentors.edit',props.row.id)"><i title="Edit Mentor" class="feather icon-edit"></i></a>
                                <a  href=""  @click.prevent="showUpdateAlert( props.row.id, props.row.status)"><i  :title="props.row.status == 1 ? 'Disable':'Activate'" class="fa fa-user"></i></a>
                                </span>

                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Mentors'},
    props: ['mentors'],
    name: "MentorIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allMentors: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            // {label: 'S/N', field: 'sn'},
            {label: 'Name', field: 'name'},
            {label: 'Email', field: 'email'},
            {label: 'Phone', field: 'phone'},
            {label: 'Course', field: 'course'},
            {label: 'Activation Status', field: 'status'},
            {label: 'Date Joined', field: 'dateJoined'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    created() {
        this.allMentors = this.mentors.data
        this.totalRecords = this.mentors.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.mentors.paginate';

                let {data: {data: mentors}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = mentors.length;
                this.paginationOptions.perPage = mentors.per_page;
                this.allMentors = mentors
            } catch (e) {
                console.log(e);
            }
        },

        deleteMentor(id){
            this.$inertia.delete(route('admin.mentors.destroy', id),{
                preserveScroll: true,
            });

        },

        showAlert(tutorId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Mentor?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteMentor(tutorId)
                }
            });
        },

        showUpdateAlert(id, status) {
            let vInstance = this;

            let action = status ? 'deactivate' : 'activate'
            this.$swal.fire({
                title: `Are you sure you want to ${action} mentor's account?`,
                // text: "All histories associated with this course will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${action} it!`,
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.updateStatus(id)
                }
            });
        },
        updateStatus(id){
            this.$inertia.get(route('admin.mentors.updateMentorStatus', id),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },


        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>

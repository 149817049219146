<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Attendances</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Attendance
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>
                        All Attendances
                        <!-- {{ allAttendances }} -->
                    </h4>
                </div>

                <div class="card-body">

                    <vue-good-table :columns="columns" :rows="allAttendances" :line-numbers="true" compactMode :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: { field: 'dataJoined', type: 'desc' }
                    }" :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'bottom',
                            perPage: 10
                    }">

                        <div slot="emptystate" class="loading-container">
                            <img src="/images/Spinner.svg" alt="">
                        </div>
                        <template slot="table-row" slot-scope="props">

                            <span v-if="props.column.field === 'action'">

                                <span style="display: flex; justify-items: center; align-items: center;" class="dropdown">
                                    <!-- <a :href="route('admin.attendances.lessonAttendance', props.row.id)"> -->
                                    <a :href="route('admin.attendances.lessonAttendance', props.row.lessonId)">

                                        <i class="feather icon-eye" title="View Attendance"></i>
                                    </a>
                                    <a   href=""  @click.prevent="showAlert( props.row.id)">
                                        <i  title="Delete Application" class="feather icon-trash"></i>
                                    </a>
                                </span>
                            </span>
                            
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Live Lesson Attendances'},
    props: ['lessonAttendances'],
    name: "AttendanceIndex",
    layout: Layout,

    data: () => ({
        filter: {},
        isLoadingFilter: false,
        allAttendances: [],
        totalRecords: 0,
        serverParams: {
            page: 1,
            perPage: 10
        },
        paginationOptions: {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            perPageDropdown: [5, 10, 20, 50, 100, 200],
            dropdownAllowAll: false,
        },
        columns: [
            {label: 'Lesson', field: 'lesson'},
            {label: 'Module', field: 'module'},
            {label: 'Tutor', field: 'tutor'},
            // {label: 'Programme', field: 'programme'},
            {label: 'Live Class Date', field: 'lessonDate'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        tableDataLoading: false,
    }),
    created() {
        this.allAttendances = this.lessonAttendances
        console.log(this.allAttendances);
        this.totalRecords = this.lessonAttendances.total
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.paginatedData();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.paginatedData();
        },
        onSortChange(params) {
            this.serverParams.sort = {
                type: params[0].type,
                field: params[0].field,
            }
            this.paginatedData();
        },
        async paginatedData() {
            try {
                let routeName = 'admin.attendances.paginate';

                let {data:{data: lessonAttendances}} = await axios.post(route(routeName),
                    {...this.serverParams});

                this.totalRecords = lessonAttendances.total;
                this.paginationOptions.perPage = lessonAttendances.per_page;
                this.allAttendances = lessonAttendances.data
            } catch (e) {
                console.log(e);
            }
        },
        deleteAttendance(attendanceId){
            this.$inertia.delete(route('admin.attendances.destroy', attendanceId),{
                preserveScroll: true,
                onSuccess: () => {
                    location.reload()
                }
            });
        },


        showAlert(attendanceId) {
            let vInstance = this;

            this.$swal.fire({
                title: "Are you sure you want to delete this Attendance List?",
                text: "All students associated with this Attendance List will be lost. You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                confirmButtonClass: "btn btn-primary mr-3",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    vInstance.deleteAttendance(attendanceId)
                }
            });
        },
    }
}
</script>
